/*
  This is a modified version of https://github.com/leMaik/i18next-ko/
  More specifically, it's changes are here: https://github.com/leMaik/i18next-ko/pull/5

  Somehow the author said the PR is cool but did not merge (as of 2017-02-24)

Usage Examples:

<span data-bind="i18n">This sentence should be translated.</span>
<span data-bind="i18n: 'This sentence should be translated.'"></span>
<span data-bind="i18n: { key: '{{ who }} are {{ what }}', options: { who: 'Translations', what: 'fun' } }"></span>
*/
(function () {
  var i18n;
  if (typeof require !== 'undefined') {
    i18n = require('i18next').default;
  } else {
    i18n = window.i18n;
  }
  var ko, $;

  var koBindingHandler = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        var i = i18nextko._koElements.indexOf(element);
        if (i >= 0) {
          i18nextko._koElements.splice(i, 1);
          i18nextko._koCallbacks.splice(i, 1);
        }
      });
      i18nextko._koElements.push(element);
      i18nextko._koCallbacks.push(ko.bindingHandlers['i18n'].update.bind(undefined, element, valueAccessor, allBindingsAccessor, viewModel, bindingContext));
      koBindingHandler.update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },

    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
      var value = ko.toJS(valueAccessor());
      if(value === undefined){
        value = element.innerText.trim();
      }
      if (typeof value === 'string') {
        element.innerText = i18n.t(value);
      } else if (value.key) {
        var result = i18n.t(value.key, value.options);
        // as of now the only kown character that i18next messes up is the slash
        result = result.replace('&#x2F;','/');
        element.innerText = result;
      } else {
        for (var attr in value) {
          var options = value[attr];
          var translation;
          if (typeof options === 'string') {
            translation = i18n.t(options);
          } else {
            translation = i18n.t(options.key, ko.toJS(options.options));
          }
          if (attr == 'html') {
            element.innerText = translation;
          } else {
            var div = document.createElement('div');
            div.innerText = translation;
            element.setAttribute(attr, div.innerText);
          }
        }
      }
    }
  };

  var i18nextko = {
    _koElements: [],
    _koCallbacks: [],

    setLanguage: function (language) {
      i18n.changeLanguage(language);
      i18nextko._setLanguageHelper(language);
    },

    _setLanguageHelper: function(language) {
      i18nextko._language(language);
      i18nextko._koCallbacks.forEach(function (c) {
        return c.call(undefined);
      });
      if (typeof $ !== 'undefined' && typeof $.fn.i18n !== 'undefined') {
        $('html').i18n();
      }
    },

    init: function (resourceStore, language, knockout, jquery, i18next_settings) {
      ko = knockout || window.ko;
      $ = jquery || window.$;

      settings = i18next_settings || {}
      settings.lng = language || 'en'
      settings.resources = resourceStore

      i18n.init(settings);

      ko.bindingHandlers['i18n'] = koBindingHandler;
      i18nextko._language = ko.observable(language);
      i18nextko._setLanguageHelper(language);
    },

    t: function () {
      var args = arguments;
      return ko.computed(function () {
        i18nextko._language(); //to auto-update this computed observable on language changes
        return i18n.t.apply(i18n, args);
      });
    },

    i18n: i18n
  };

  if (typeof module !== 'undefined') {
    module.exports = i18nextko;
  } else {
    window.i18nextko = i18nextko;
  }
})();

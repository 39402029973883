import * as ko from 'knockout';
import { MaybeKO, asObservable, createWithComponent } from '@core/utils/ko_utils';
import { ChecklistRow, ChecklistStateItem, ChecklistUserType } from 'models/business_line_checklist';

let template = require('../../templates/components/product_checklist.html').default;

class ProductChecklist {
    hasTPP: boolean;
    checklist: ko.Observable<ChecklistRow[]>;
    canEdit: (userType: string) => boolean;

    constructor(params: { hasTPP: boolean, checklist: MaybeKO<ChecklistRow[]>, canEdit: (userType: ChecklistUserType) => boolean }) {
        this.hasTPP = params.hasTPP;
        this.checklist = asObservable(params.checklist);
        this.canEdit = params.canEdit;
    }

    flipState = (state: ChecklistStateItem) => {
        if (!this.canEdit(state.userType)) {
            return;
        }

        if (state.value() === 'empty') {
            state.value('approved');
        } else if (state.value() === 'approved') {
            state.value('declined');
        } else if (state.value() === 'declined') {
            state.value('empty');
        }
    }
}

ko.components.register('product-checklist', { viewModel: createWithComponent(ProductChecklist), template: template });

import * as ko from 'knockout';

import { SiteData, sitesApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { Site } from 'models/site';

let template = require('../../templates/site_edit.html').default;

class SiteEditScreen extends BaseEditScreen<SiteData, Site> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<SiteData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: sitesApi, focus: true }, params, componentInfo);
    }

    instantiate(data: SiteData) {
        return new Site(data);
    }
}

export let siteEdit = { name: 'site-edit', viewModel: createWithComponent(SiteEditScreen), template: template };

ko.components.register(siteEdit.name, siteEdit);

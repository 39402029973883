import * as ko from 'knockout';

import { TagData } from '../api/simple_api';

export class Tag {
    private id: string | null = null;

    name = ko.observable('').extend({ required: true, serverError: true });

    constructor(data: TagData) {
        if (data) {
            this.id = data.id;
            this.name(data.name);
        }
    }

    toData(): TagData {
        return {
            id: this.id,
            name: this.name()
        };
    }
}

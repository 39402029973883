import * as ko from 'knockout';

export type MaybeKO<T> = ko.Observable<T> | ko.Computed<T> | T;
export type KOMaybeArray<T> = ko.Observable<T | null> | ko.ObservableArray<T>;

export function asObservable<T>(value: MaybeKO<T>): ko.Observable<T> {
    if (ko.isSubscribable(value)) {
        return <ko.Observable<T>>value;
    } else {
        return ko.observable(<T>value);
    }
}

export function setMaybeObservable<T, K extends keyof T>(obj: T, attr: K, value: T[K]) {
    let obs = obj[attr];
    if (ko.isObservable(obs)) {
        obs(value);
    } else {
        obj[attr] = value;
    }
}

interface ComponentConstructor<T> {
    new(params: T, componentInfo: ko.components.ComponentInfo): {}
}

export function createWithComponent<T>(constructor: ComponentConstructor<T>) {
    return {
        createViewModel: (params: T, componentInfo: ko.components.ComponentInfo): {} => {
            return new constructor(params, componentInfo);
        }
    }
}

export function makeHasError(...items: ko.Observable<{}>[]) {
    let group = ko.validation.group(items, { deep: true });
    return ko.pureComputed(() => {
        if ((<any>group).isAnyMessageShown()) {
            return true;
        }

        for (let item of items) {
            if (item && item.serverError && item.serverError()) {
                return true;
            }
        }

        return false;
    });
}

export function unwrap<T>(value: null | undefined | T | KnockoutObservable<T>): T | null | undefined {
    if (value === null) {
        return null;
    }
    if (value === undefined) {
        return undefined;
    }

    return ko.unwrap(value);
}

/// <reference path="./type_definitions/pickadate.d.ts" />


import * as ko from 'knockout';
import * as i18nextko from '../js/i18next-ko.js';
import { requestWithStatus } from './api/base_request';

interface InterpolationOptions {
    escapeValue?: boolean;
    prefix?: string;
    suffix?: string;
    prefixEscaped?: string;
    suffixEscaped?: string;
    unescapeSuffix?: string;
    unescapePrefix?: string;
    nestingPrefix?: string;
    nestingSuffix?: string;
    nestingPrefixEscaped?: string;
    nestedSuffixEscaped?: string;
    defaultVariables?: any;
  }

interface TranslationOptions {
    defaultValue?: string;
    count?: number;
    context?: any;
    replace?: any;
    lng?: string;
    lngs?: string[];
    fallbackLng?: string;
    ns?: string | string[];
    keySeparator?: string;
    nsSeparator?: string;
    returnObjects?: boolean;
    joinArrays?: string;
    postProcess?: string | any[];
    interpolation?: InterpolationOptions;
  }

interface ResourceStore {
    [language: string]: ResourceStoreLanguage;
}
interface ResourceStoreLanguage {
    [namespace: string]: ResourceStoreKey;
}
interface ResourceStoreKey {
    [key: string]: any;
}

interface ResourceStoreResult {
    status: number;
    result: ResourceStoreKey;
}

interface TranslationFunction {
    (key: string, options?: any): ko.Computed<string>;
}

interface I18NextKo {
     init(
         resourceStore: ResourceStore,
         language: string,
         ko?: {}, jquery?: any,
         translationOptions?: TranslationOptions ): void;
     setLanguage(language: string): void;
     t: TranslationFunction;
}

class MyI18n {
    i18nko: I18NextKo = i18nextko;
    languageCode: string = 'en';
    t: TranslationFunction = this.i18nko.t;
    userInputLanguage = ko.observable<string>('en');
    showDetails = ko.observable(localStorage.getItem('showTranslationsDetails') === 'true');

    constructor() {
        this.initializeTranslations();
    }

    setupKnockoutValidationLocalization() {
        let preferedLanguage = this.getUserPreferedLanguage();
        ko.validation.defineLocale(preferedLanguage, this.getKnockoutValidationSettings());
        ko.validation.locale(preferedLanguage);
    }

    private getKnockoutValidationSettings = () : KnockoutValidationLocalizationDictionary => {
        // source: https://github.com/Knockout-Contrib/Knockout-Validation/blob/master/localization/
        let languageCode = this.getUserPreferedLanguage();
        if (languageCode == 'tt') {
            return {
                required: '[thIs fIEld Is rEqUIrED.]',
                min: '[plEAsE EntEr A vAlUE grEAtEr thAn Or EqUAl tO {0}.]',
                max: '[plEAsE EntEr A vAlUE less thAn Or EqUAl tO {0}.]',
                minLength: '[plEAsE EntEr At lEAst {0} chArActErs.]',
                maxLength: '[plEAsE EntEr nO mOrE thAn {0} chArActErs.]',
                pattern: '[plEAsE check thIs vAlUE.]',
                step: '[The vAlUE mUst IncrEmEnt by {0}.]',
                email: '[plEAsE EntEr A prOpEr EmAil AddrEss.]',
                date: '[plEAsE EntEr A prOpEr dAtE.]',
                dateISO: '[plEAsE EntEr A prOpEr dAtE.]',
                number: '[plEAsE EntEr A nUmbEr.]',
                digit: '[plEAsE EntEr A dIgIt.]',
                phoneUS: '[plEAsE spEcIfy A vAlId phOnE nUmbEr.]',
                equal: '[vAlUEs mUst EqUAl.]',
                notEqual: '[plEAsE chOOsE AnOthEr vAlUE.]',
                unique: '[plEAsE make sure the vAlUE Is UnIqUE.]'
            }
        }
        if (languageCode == 'pt') {
            return {
                required: 'Este campo é obrigatório.',
                min: 'Por favor, informe um valor maior ou igual a {0}.',
                max: 'Por favor, informe um valor menor ou igual a {0}.',
                minLength: 'Por favor, informe ao menos {0} caracteres.',
                maxLength: 'Por favor, informe no máximo {0} caracteres.',
                pattern: 'Por favor, verifique este valor',
                step: 'O valor deve ser incrementado por {0}',
                email: 'Por favor, informe um e-mail válido.',
                date: 'Por favor, informe uma data válida.',
                dateISO: 'Por favor, informe uma data válida (ISO).',
                number: 'Por favor, informe um número válido.',
                digit: 'Por favor, utilize somente dígitos.',
                phoneUS: 'Por favor, informe um telefone válido',
                equal: 'Os valores devem ser iguais',
                notEqual: 'Por favor, informe outro valor',
                unique: 'Verifique se o valor é único'
            }
        }
        if (languageCode == 'fr') {
            return {
                required: 'Ce champ est obligatoire.',
                min: 'Veuillez saisir une valeur supérieure ou égale à {0}.',
                max: 'Veuillez saisir une valeur inférieure ou égale à {0}.',
                minLength: 'Veuillez saisir au moins {0} caractères.',
                maxLength: 'Veuillez saisir au plus {0} caractères.',
                pattern: 'Veuillez corriger ce champ.',
                step: 'Le pas d\'incrémentation de la valeur doit être de {0}.',
                email: 'Ceci n\'est pas une adresse électronique valide.',
                date: 'Veuillez saisir une date valide.',
                dateISO: 'Veuillez saisir une date (ISO) valide.',
                number: 'Veuillez saisir un nombre.',
                digit: 'Veuillez saisir un chiffre.',
                phoneUS: 'Veuillez saisir un numéro de téléphone valide.',
                equal: 'Les valeurs doivent être égales.',
                notEqual: 'Veuillez saisir une autre valeur.',
                unique: 'Veuillez vérifier que la valeur est unique.'
            }
        }
        return {
            required: 'This field is required.',
            min: 'Please enter a value greater than or equal to {0}.',
            max: 'Please enter a value less than or equal to {0}.',
            minLength: 'Please enter at least {0} characters.',
            maxLength: 'Please enter no more than {0} characters.',
            pattern: 'Please check this value.',
            step: 'The value must increment by {0}.',
            email: 'Please enter a proper email address.',
            date: 'Please enter a proper date.',
            dateISO: 'Please enter a proper date.',
            number: 'Please enter a number.',
            digit: 'Please enter a digit.',
            phoneUS: 'Please specify a valid phone number.',
            equal: 'Values must equal.',
            notEqual: 'Please choose another value.',
            unique: 'Please make sure the value is unique.'
        }
    }


    getDatePickerSettings = () : Pickadate.DateOptions => {
        // more available from https://github.com/amsul/pickadate.js/tree/3.5.6/lib/translations
        // this must be a special case or else the translators will for sure do something wrong
        // also, we would need this for firstDay anyway
        let languageCode = this.getUserPreferedLanguage();
        if (languageCode == 'pt') {
            return {
                monthsFull: [ 'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro',  'novembro', 'dezembro' ],
                monthsShort: [ 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez' ],
                weekdaysFull: [ 'domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira',           'sábado' ],
                weekdaysShort: [ 'dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab' ],
                today: 'hoje',
                clear: 'limpar',
                close: 'fechar',
                format: 'dd de mmmm de yyyy',
                formatSubmit: 'yyyy/mm/dd'
            }
        }
        if (languageCode == 'fr') {
            return {
                monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
                weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                today: 'Aujourd\'hui',
                clear: 'Effacer',
                close: 'Fermer',
                firstDay: 1,
                format: 'dd mmmm yyyy',
                // formatSubmit: 'yyyy/mm/dd',
                labelMonthNext:"Mois suivant",
                labelMonthPrev:"Mois précédent",
                labelMonthSelect:"Sélectionner un mois",
                labelYearSelect:"Sélectionner une année",
            };
        }

        if (languageCode == 'tt') {
            return {
                monthsFull: ['[jAnUAry]', '[fEbrUArY', '[mArch]', '[AprIl]', '[mAI]', '[jUnE]', '[jUly]', '[AUgUst]', '[sEptEmbEr]', '[OctObEr]', '[nOvEmbEr]', '[dEcEmbEr]'],
                monthsShort: ['[jAn]', '[fEv]', '[mAr]', '[apr]', '[mAI]', '[jUnE]', '[jUlY]', '[AUg]', '[sEp]', '[Oct]', '[nOv]', '[dEc]'],
                weekdaysFull: ['[sUndAy]', '[mOndAy]', '[tUEsdAy]', '[wEdnEsdAy]', '[thUrsdAy]', '[frIddAy]', '[sAtUrdAy]'],
                weekdaysShort: ['[sUn]', '[mOn]', '[tUE]', '[wEd]', '[thU]', '[frI]', '[sAt]'],
                today: '[tOdAy]',
                clear: '[clEAr]',
                close: '[clOsE]',
                firstDay: 1,
                format: '[dd mmmm, yyyy]',
                // formatSubmit: 'yyyy/mm/dd',
                labelMonthNext:"[nExT mOnth]",
                labelMonthPrev:"[lAst mOnth]",
                labelMonthSelect:"[sElEct A mOnth]",
                labelYearSelect:"[sElEct A yEAr]",
            }
        }

        return {
            monthsFull: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            format: 'dd mmmm, yyyy',
        }
    }

    initializeTranslations = () : Promise<{} | null> => {
        // first we load english
        this.initializeTranslationModule('en', null);
        // then we asyncronisly load the rest
        let languageCode = this.getUserPreferedLanguage();
        this.userInputLanguage(languageCode);

        if (languageCode === 'en') {
            return Promise.resolve(null);
        }
        return this.loadTranslationFromServer(languageCode).then((langResult) => {
            this.initializeTranslationModule(languageCode, langResult);
            return null;
        });
    }

    setUserPreferedLanguage = (preferedLanguage: string) : void => {
        localStorage.setItem('userLanguage', preferedLanguage);
    }

    getUserPreferedLanguage = () : string => {
        let localStorageLanguage = localStorage.getItem('userLanguage');
        if (localStorageLanguage !== null){
            return localStorageLanguage;
        }

    //   let languageArray = this.getLanguageArray();
       /* for( let i in languageArray){
           let aBrowserSupportedLanguage = languageArray[i];
           let normalizedLanguageName = aBrowserSupportedLanguage.substring(0, 2).toLowerCase();
            for( let j in SERVER_INFO.LANGUAGE_LIST ){
                let availableLanguage = SERVER_INFO.LANGUAGE_LIST[j]['code'];
                if(normalizedLanguageName === availableLanguage){
                    this.setUserPreferedLanguage(normalizedLanguageName);
                    return normalizedLanguageName;
                }
            }
        }*/
        return 'en';
    }

    setShowDetails = (show: boolean) : void => {
        localStorage.setItem('showTranslationsDetails', show ? 'true' : '');
        this.showDetails(show);
    }
/*
    private getLanguageArray() : string[]{
        // Firefox and Chrome only, same as browser header "Accept-Language"
        let languageArray = ((navigator as any).languages as string[]);
        if (languageArray === undefined){
            languageArray = new Array<string>();
        }else{
            languageArray = languageArray.slice(0); // the original array is read only
        }

        // IE and Edge, language of the Windows UI or the browser language in case of Safari
        let detectedLanguage = (navigator as any).userLanguage || window.navigator.language;
        if (detectedLanguage !== undefined && detectedLanguage != null && detectedLanguage != ""){
            languageArray.push(detectedLanguage);
        }
        return languageArray;
    }

*/
    private loadTranslationFromServer(language: string): Promise<ResourceStoreResult> {
        return requestWithStatus('GET', '/i18n/' + language + '.json', undefined);
    }

    private initializeTranslationModule = (languageCode: string, langResult: ResourceStoreResult | null) => {
        let languageObj: ResourceStore = {};
        let availableLanguageCode = languageCode;
        if (langResult !== null && langResult.status === 200) {
            languageObj[availableLanguageCode] = {
                translation: langResult.result,
            };
        } else {
            availableLanguageCode = 'en';
        }
        i18nextko.init(
            languageObj,
            availableLanguageCode,
            ko,
            false,
            { nsSeparator: false, keySeparator: false, debug: availableLanguageCode === 'tt' },
        );
        this.languageCode = languageCode;
    }

}

let i18n = new MyI18n();
export default i18n;

import page from 'page';
import * as ko from 'knockout';

import { BusinessLineData, businessLinesApi, SuggestedBusinessLineData, suggestedBusinessLinesApi, usersApi, BusinessLineIncludeType, BusinessLinePhase, isSuggested, SiteData, syncTrialData } from '../api/simple_api';
import { BusinessLine, BusinessLineSampleDescr } from '../models/business_line';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent, makeHasError } from '@core/utils/ko_utils';
import { BaseForm } from '@core/screens/base_form';
import { ValidationResult } from '@core/api/request';
import i18n from '@core/i18n';
import { confirmNavigation, clearConfirmNavigation } from '@core/utils/routes';
import { deepEquals } from '@core/utils';
import { BusinessLineCommentsModel } from 'components/business_line_comments';
import { Action } from '@core/components/basic_widgets';
import { mapSelectPopup } from '@core/components/map_select';
import { app } from '@core/app';
import { session } from '@core/session';
import { getDefaultTranslation } from '@core/i18n_text';
import { importDevelopmentDialog } from '../components/import_development';


let template = require('../../templates/business_line_edit.html').default;

interface Step {
    id: string;
    title: string;
    completed: KnockoutComputed<boolean>;
    hasErrors: KnockoutComputed<boolean>;
    comments?: BusinessLineCommentsModel;
}

type Phase = 'phase1' | 'phase2' | 'phase3' | 'phase4' | 'phase5' | 'discard';

function makeCompleted(entityObs: ko.Observable<BusinessLine>, ...props: (keyof BusinessLine)[]): KnockoutComputed<boolean> {
    return ko.pureComputed(() => {
        let entity = entityObs();
        if (!entity) {
            return false;
        }

        for (let prop of props) {
            let value = entity[prop];
            if (!ko.isObservable(value)) {
                continue
            }

            if ('push' in value) {
                if (value().length === 0) {
                    return false;
                }
            } else if (!value()) {
                return false;
            }
        }

        return true;
    });
}

function makeError(entityObs: ko.Observable<BusinessLine>, other: (() => boolean) | null, ...props: (keyof BusinessLine)[]): KnockoutComputed<boolean> {
    let hasError: () => boolean = null;

    return ko.pureComputed(() => {
        let entity = entityObs();
        if (!entity) {
            return false;
        }

        if (!hasError) {
            hasError = makeHasError.apply(this, props.map(prop => entity[prop]));
        }

        return hasError() || (other && other());
    });
}

class BusinessLineEditScreen extends BaseForm<BusinessLineData> {
    syncingTrial = ko.observable(false);
    entity = ko.validatedObservable<BusinessLine>(null);
    globalError = ko.observable('');
    selected = ko.observable<Phase>('phase1');
    showDisabledDevRecords = ko.observable(false);
    visibleRecords = ko.computed(() => {
        const records = this.entity()?.selectedDevelopment()?.records() || [];
        if (this.showDisabledDevRecords()) {
            return records;
        }      
        return records.filter(record => !record.disabled())
    });
    designSystem = ko.observable<"gate" | "ft">("gate");
    canSetDesignSystem = ko.pureComputed(
        () =>
          this.entity().linkedTrials.length === 0 &&
          this.entity().testSubjects().length === 0 &&
          this.entity().sampleDescriptions().length === 0 &&
          this.entity().developments().length === 0
      );

    private tppPhaseNumber = 0;

    hasPhase1Error: ko.Computed<boolean>;
    hasPhase2Error: ko.Computed<boolean>;
    hasPhase3Error: ko.Computed<boolean>;
    hasPhase4Error: ko.Computed<boolean>;
    hasPhase5Error: ko.Computed<boolean>;
    hasDiscardError: ko.Computed<boolean>;

    getActions(sampleDescr: BusinessLineSampleDescr): Action[] {
        let actions = [
            {
                icon: 'map',
                title: i18n.t('Select')(),
                cssClass: '',
                onClick: () => mapSelectPopup(sampleDescr.site, sampleDescr.siteSearch),
            },
            {
                icon: 'create',
                title: i18n.t('Create')(),
                cssClass: '',
                onClick: () => app.formsStackController.push({
                    title: i18n.t('Create site')(),
                    name: 'site-edit',
                    params: {
                        result: new Deferred<SiteData>(),
                    }
                }).then((createdSite: SiteData) => sampleDescr.site(createdSite)),
            }
        ];
        if (sampleDescr.site() != null) {
            actions.push({
                icon: 'close',
                title: i18n.t('Remove')(),
                cssClass: '',
                onClick: () => sampleDescr.site(null)
            });
        }
        return actions;
    }

    // one per phase
    private steps: { [key: string]: Step[] } = {
        phase1: [
            {
                id: 'phase_start',
                title: i18n.t('Phase start')(),
                completed: makeCompleted(this.entity, 'name', 'phase1ChangedAt', 'country', 'crops', 'categories', 'tags'),
                hasErrors: makeError(this.entity, null, 'name', 'phase1ChangedAt', 'country', 'crops', 'categories', 'tags')
            },
            {
                id: 'task',
                title: i18n.t('Team')(),
                completed: makeCompleted(this.entity, 'owner', 'supervisor', 'viewers', 'partners', 'taskIdentification'),
                hasErrors: makeError(this.entity, null, 'owner', 'supervisor', 'viewers', 'partners', 'taskIdentification')
            },
            {
                id: 'relevance',
                title: i18n.t('Relevance')(),
                completed: makeCompleted(this.entity, 'relevantReason', 'goal', 'hypothesis'),
                hasErrors: makeError(this.entity, null, 'relevantReason', 'goal', 'hypothesis')
            },
            {
                id: 'market',
                title: i18n.t('Ecosystem viability')(),
                completed: makeCompleted(this.entity, 'marketSize', 'potentialAdoption', 'potentialAdoptionReason', 'targetedMarket'),
                hasErrors: makeError(this.entity, null, 'marketSize', 'potentialAdoption', 'potentialAdoptionReason', 'targetedMarket')
            },
            {
                id: 'social_env_impact',
                title: i18n.t('Social and environmental impact')(),
                completed: makeCompleted(this.entity, 'promotingEquality', 'alignFramework'),
                hasErrors: makeError(this.entity, null, 'promotingEquality', 'alignFramework')
            },
            {
                id: 'additional_info',
                title: i18n.t('Additional information')(),
                completed: ko.pureComputed(() => {
                    return !!(this.entity()
                                && this.entity().titlePictureUpload.picturePublicURL()
                                && (this.entity().phase1Comment() || this.entity().hasAnyFile(0) || this.entity().hasAnyFile(1)));
                }),
                hasErrors: makeError(this.entity, null, 'phase1Comment')
            },
            {
                id: 'checklist',
                title: i18n.t('Progression checklist')(),
                completed: ko.pureComputed(() => this.validated(1)),
                hasErrors: ko.pureComputed(() => false)
            },
            {
                id: 'public_messages',
                title: i18n.t('Messages from users')(),
                completed: ko.pureComputed(() => true),
                hasErrors: ko.pureComputed(() => false)
            }
        ],
        phase2: [
            {
                id: 'phase_start',
                title: i18n.t('Phase start')(),
                completed: makeCompleted(this.entity, 'phase2ChangedAt', 'sampleDescriptions', 'testSubjects'),
                hasErrors: makeError(this.entity, () => this.hasTestSubjectsErrors(), 'phase2ChangedAt', 'sampleDescriptions', 'testSubjects')
            },
            {
                id: 'factors',
                title: i18n.t('Factors to be evaluated')(),
                completed: ko.pureComputed(() => {
                    const entity = this.entity();
                    return entity.developments().length > 0 && entity.developments().every(dev => dev.factors().length > 0);
                }),
                hasErrors: ko.pureComputed(() => this.hasFactorsErrors())
            },
            {
                id: 'materials',
                title: i18n.t('Materials and budget')(),
                completed: makeCompleted(this.entity, 'sourcesRequirements', 'proposedDuration', 'budgets'),
                hasErrors: makeError(this.entity, () => this.hasBudgetsErrors(), 'sourcesRequirements', 'proposedDuration', 'budgets')
            },
            {
                id: 'additional_info',
                title: i18n.t('Additional information')(),
                completed: makeCompleted(this.entity, 'phase2Comment'),
                hasErrors: makeError(this.entity, null, 'phase2Comment')
            },
            {
                id: 'checklist',
                title: i18n.t('Progression checklist')(),
                completed: ko.pureComputed(() => this.validated(2)),
                hasErrors: ko.pureComputed(() => false)
            }
        ],
        phase3: [
            {
                id: 'rounds',
                title: i18n.t('Rounds')(),
                completed: makeCompleted(this.entity, 'developments'),
                hasErrors: ko.pureComputed(() => this.hasDevelopmentsErrors())
            },
            {
                id: 'additional_info',
                title: i18n.t('Additional information')(),
                completed: makeCompleted(this.entity, 'developments'),
                hasErrors: ko.pureComputed(() => false)
            },
            {
                id: 'social_env_impact',
                title: i18n.t('Social and environmental impact')(),
                completed: ko.pureComputed(() => false),
                hasErrors: ko.pureComputed(() => false),
            },
            {
                id: 'checklist',
                title: i18n.t('Progression checklist')(),
                completed: ko.pureComputed(() => this.validated(3)),
                hasErrors: ko.pureComputed(() => false)
            },
            {
                id: 'tpp_results',
                title: i18n.t('TPP Results')(),
                completed: ko.pureComputed(() => true),
                hasErrors: ko.pureComputed(() => false)
            }
        ],
        phase4: [
            {
                id: 'phase_start',
                title: i18n.t('Phase start')(),
                completed: makeCompleted(this.entity, 'phase4ChangedAt'),
                hasErrors: makeError(this.entity, null, 'phase4ChangedAt', 'fieldtrialsExpansion', 'fieldtrialsExpansionComment')
            },
            {
                id: 'potential_markets',
                title: i18n.t('Value proposition')(),
                completed: makeCompleted(this.entity, 'benefitsEstimation'),
                hasErrors: makeError(this.entity, null, 'phase4PotentialMarkets', 'phase4PotentialMarketsComment', 'benefitsEstimation')
            },
            {
                id: 'additional_enabling_factors',
                title: i18n.t('Additional enabling factors')(),
                completed: makeCompleted(this.entity, 'suppliers', 'training', 'infrastructure', 'regulation'),
                hasErrors: makeError(this.entity, null, 'suppliers', 'training', 'infrastructure', 'regulation')
            },
            {
                id: 'additional_info',
                title: i18n.t('Additional information')(),
                completed: makeCompleted(this.entity, 'phase4Comment'),
                hasErrors: makeError(this.entity, null, 'phase4Comment')
            },
            {
                id: 'checklist',
                title: i18n.t('Progression checklist')(),
                completed: ko.pureComputed(() => this.validated(4)),
                hasErrors: ko.pureComputed(() => false)
            },
            {
                id: 'pre_commercial_productions',
                title: i18n.t('Production information')(),
                completed: ko.pureComputed(() => true),
                hasErrors: ko.pureComputed(() => false)
            }
        ],
        phase5: [
            {
                id: 'phase_start',
                title: i18n.t('Phase start')(),
                completed: makeCompleted(this.entity, 'phase5ChangedAt', 'productProfile', 'profileDocUrl'),
                hasErrors: makeError(this.entity, null, 'phase5ChangedAt', 'productProfile')
            },
            {
                id: 'external_investors',
                title: i18n.t('External investors')(),
                completed: makeCompleted(this.entity, 'nextEvaluationTimeFrame'),
                hasErrors: makeError(this.entity, null, 'externalInvestors', 'externalInvestorsComment', 'nextEvaluationTimeFrame')
            },
            {
                id: 'additional_info',
                title: i18n.t('Additional information')(),
                completed: makeCompleted(this.entity, 'phase5Comment'),
                hasErrors: makeError(this.entity, null, 'phase5Comment')
            },
            {
                id: 'checklist',
                title: i18n.t('Progression checklist')(),
                completed: ko.pureComputed(() => this.validated(5)),
                hasErrors: ko.pureComputed(() => false)
            },
            {
                id: 'commercial_productions',
                title: i18n.t('Production information')(),
                completed: ko.pureComputed(() => true),
                hasErrors: ko.pureComputed(() => false)
            }
        ],
        discard: [
            {
                id: 'phase_start',
                title: i18n.t('Phase start')(),
                completed: makeCompleted(this.entity, 'discardChangedAt'),
                hasErrors: makeError(this.entity, null, 'discardChangedAt')
            },
            {
                id: 'additional_info',
                title: i18n.t('Additional information')(),
                completed: makeCompleted(this.entity, 'discardComment'),
                hasErrors: makeError(this.entity, null, 'discardComment')
            }
        ]
    };

    phaseSteps = ko.pureComputed(() => {
        let steps = this.steps[this.selected()];

        if (this.entity().hasTPP) {
            return steps;
        } else {
            return steps.filter(step => step.id !== 'tpp_results' && step.id !== 'pre_commercial_productions' && step.id !== 'commercial_productions');
        }
    });
    currentStep = ko.observable<Step>(this.steps['phase1'][0]);

    private lastSavedData: BusinessLineData | null = null;
    private autoSaveToken: number | null = null;

    private include: BusinessLineIncludeType;

    constructor(params: { id: string, promoteId?: string, initialName?: string, result?: Deferred<BusinessLineData>, include: BusinessLineIncludeType, phase?: string, step?: string }, componentInfo: ko.components.ComponentInfo) {
        super(params);

        this.include = params.include;

        let dataPromise: Promise<BusinessLineData | SuggestedBusinessLineData>;
        if (params.promoteId) {
            dataPromise = suggestedBusinessLinesApi.retrieve(params.promoteId);
        } else if (params.id) {
            dataPromise = businessLinesApi.retrieve(params.id);
        } else {
            dataPromise = undefined;
        }
        let userPromise = usersApi.me();
        let promise = Promise.all([userPromise, dataPromise]).then(([user, data]) => {
            this.entity(new BusinessLine(user, data, params.promoteId));
            if (!data && this.entity().name && params.initialName) {
                this.entity().name(params.initialName);
            }
            if (this.entity().linkedTrials.length > 0) {
                this.designSystem("ft");
            }
            this.setupEntity(!data || isSuggested(data) ? 'definition' : data.cur_phase);

            this.lastSavedData = this.entity().toData();
            //@ts-ignore
            this.autoSaveToken = setInterval(this.autoSave, 2 * 60 * 1000);

            if (['phase1', 'phase2', 'phase3', 'phase4', 'phase5'].indexOf(params.phase) > -1) {
                this.selectPhase(params.phase as Phase);
            }
            if (params.step) {
                for (let step of this.phaseSteps()) {
                    if (step.id === params.step) {
                        this.selectStep(step);
                        break;
                    }
                }
            }
        });
        this.loadedAfter(promise);

        confirmNavigation(
            i18n.t('There are unsaved changes. If you leave the page you will lose them. Are you sure you want to continue?')(),
            this.hasUnsavedChanges
        )
    }

    private hasFactorsErrors() {
        let entity = this.entity();
        if (!entity) {
            return false;
        }

        return entity.developments().some(dev => dev.hasDesignError());
    }

    private hasTestSubjectsErrors() {
        let entity = this.entity();
        if (!entity) {
            return false;
        }

        return entity.testSubjects().some(ts => ts.hasErrors());
    }

    private hasBudgetsErrors() {
        let entity = this.entity();
        if (!entity) {
            return false;
        }

        return entity.budgets().some(budget => budget.hasErrors());
    }

    private hasDevelopmentsErrors() {
        let entity = this.entity();
        if (!entity) {
            return false;
        }

        return this.entity().developments().some(dev => dev.hasError());
    }

    private setupEntity(initialPhase: BusinessLinePhase) {
        let entity = this.entity();
        this.hasPhase1Error = makeHasError(
            entity.country, entity.crops, entity.categories, entity.tags, entity.owner, entity.supervisor, entity.partners,
            entity.relevantReason, entity.goal, entity.hypothesis,
            entity.taskIdentification, entity.phase1ChangedAt,
            entity.phase1Comment, entity.name
        );
        let phase2Errors = makeHasError(entity.sampleDescriptions, entity.phase2ChangedAt, entity.phase2Comment, entity.proposedDuration);
        this.hasPhase2Error = ko.pureComputed(() => {
            if (phase2Errors()) {
                return true;
            }
            if (this.hasFactorsErrors()) {
                return true;
            }
            if (this.hasTestSubjectsErrors()) {
                return true;
            }

            return this.hasBudgetsErrors();
        });
        this.hasPhase3Error = ko.pureComputed(() => this.hasDevelopmentsErrors());
        this.hasPhase4Error = makeHasError(
            entity.fieldtrialsExpansion, entity.fieldtrialsExpansionComment, entity.phase4PotentialMarkets, entity.phase4PotentialMarketsComment,
            entity.benefitsEstimation, entity.phase4ChangedAt, entity.phase4Comment
        );
        this.hasPhase5Error = makeHasError(
            entity.productProfile, entity.externalInvestors, entity.externalInvestorsComment, entity.nextEvaluationTimeFrame,
            entity.phase5ChangedAt, entity.phase5Comment
        );
        this.hasDiscardError = makeHasError(entity.discardChangedAt, entity.discardComment);

        if (initialPhase === 'discard') {
            this.tppPhaseNumber = 6;
            this.selectDiscard();
        } else if (initialPhase === 'commercial') {
            this.tppPhaseNumber = 5;
            this.selectPhase5();
        } else if (initialPhase === 'pre_commercial') {
            this.tppPhaseNumber = 4;
            this.selectPhase4();
        } else if (initialPhase === 'development') {
            this.tppPhaseNumber = 3;
            this.selectPhase3();
        } else if (initialPhase === 'designing') {
            this.tppPhaseNumber = 2;
            this.selectPhase2();
        } else {
            this.tppPhaseNumber = 1;
        }
    }

    developmentsExcelImport() {
        const openImportForm = () => app.formsStackController.push({
            title: i18n.t("Import Development Records")(),
            name: importDevelopmentDialog.name,
            params: {
                result: new Deferred<{}>(),
                innovationId: this.entity().id,
            },
            showNav: true
        });
        this.save(openImportForm);
    }
    developmentsExcelImportBound = this.developmentsExcelImport.bind(this);


    dispose() {
        if (this.autoSaveToken) {
            clearInterval(this.autoSaveToken);
            this.autoSaveToken = null;
        }
        clearConfirmNavigation();
    }

    close() {
        if (this.include === 'all') {
            page('/innovations/');
        } else if (this.include === 'project_manager') {
            page('/pm_innovations/');
        } else if (this.include == 'supervisor') {
            page('/supervisor_innovations/');
        } else {
            page('/suggested_innovations/');
        }
    }

    hasUnsavedChanges = () => {
        if (!this.entity()) {
            return false;
        }

        if (!this.entity().id()) {
            return true;
        }

        return !deepEquals(this.lastSavedData, this.entity().toData());
    };

    showSaveError = ko.observable(false);
    showSaveSuccess = ko.observable(false);

    private timeoutToken: number = null;

    save = (onSuccess: (entityId: string) => void = undefined) => {
        clearTimeout(this.timeoutToken);
        this.showSaveError(false);
        this.showSaveSuccess(false);

        let showMsgs = (valid: boolean) => {
            this.showSaveError(!valid);
            this.showSaveSuccess(valid);

            this.timeoutToken = window.setTimeout(() => {
                this.showSaveError(false);
                this.showSaveSuccess(false);
            }, 5000);
        };

        let savedData = this.entity().toData();
        this.saveEntity(businessLinesApi, this.entity, this.globalError, validation => {
            if (validation.isValid) {
                this.entity().updateIds(savedData, validation.result);
                this.lastSavedData = savedData;
            }
            if (typeof onSuccess === 'function') {
                onSuccess(validation.entityId);
            }

            showMsgs(validation.isValid);
        }, () => showMsgs(false));
    };

    autoSave = () => {
        if (this.hasUnsavedChanges()) {
            this.save();
        }
    };

    onRemoteValidation<TR>(resultData: BusinessLineData, entity: any, validation: ValidationResult<TR>, onClose?: (validation: ValidationResult<TR>) => void) {
        super.onRemoteValidation(resultData, entity, validation, onClose);

        if (!validation.isValid) {
            let budgetErrors: any = validation.errors['budgets'];
            if (budgetErrors) {
                for (let i = 0; i < budgetErrors.length; i++) {
                    this.applyModelServerErrors(this.entity().budgets()[i], budgetErrors[i]);
                }
            }
            let descrErrors: any = validation.errors['sample_descriptions'];
            if (descrErrors) {
                for (let i = 0; i < descrErrors.length; i++) {
                    this.applyModelServerErrors(this.entity().sampleDescriptions()[i], descrErrors[i]);
                }
            }
        }
    }

    validated(num: number) {
        let entity = ko.unwrap(this.entity);

        if (entity.hasTPP) {
            return num <= this.tppPhaseNumber;
        }

        return entity.checklist()[num - 1].every(row => row.states.every(state => state.value() === 'approved'));
    }

    rejected(num: number) {
        let entity = ko.unwrap(this.entity);

        if (entity.hasTPP) {
            return false;
        }

        return entity.checklist()[num - 1].some(row => row.states.some(state => state.value() === 'declined'));
    }

    selectPhase1 = () => this.selectPhase('phase1');
    selectPhase2 = () => this.selectPhase('phase2');
    selectPhase3 = () => this.selectPhase('phase3');
    selectPhase4 = () => this.selectPhase('phase4');
    selectPhase5 = () => this.selectPhase('phase5');
    selectDiscard = () => this.selectPhase('discard');

    private selectPhase(phase: Phase) {
        this.selected(phase);
        this.currentStep(this.steps[phase][0]);
    }

    isStepActive = (step: Step) => {
        return this.currentStep() === step;
    };

    showStep = (phase: string, id: string) => {
        return this.selected() === phase && this.currentStep().id === id;
    };

    selectStep = (step: Step) => {
        this.currentStep(step);
    };

    nextStep = () => {
        let steps = this.phaseSteps();
        let idx = steps.indexOf(this.currentStep()) + 1;

        if (idx >= steps.length) {
            let phase = this.selected();
            if (phase === 'phase1') {
                this.selectPhase2();
            } else if (phase === 'phase2') {
                this.selectPhase3();
            } else if (phase === 'phase3') {
                this.selectPhase4();
            } else if (phase === 'phase4') {
                this.selectPhase5();
            } else if (phase === 'phase5') {
                this.selectDiscard();
            }
        } else {
            this.selectStep(steps[idx]);
        }
    }

    currentStepComments() {
        let step = this.currentStep();
        if (!step.comments) {
            let phase = this.selected();
            let phaseNumber: number;
            if (phase === 'phase1') {
                phaseNumber = 0;
            } else if (phase === 'phase2') {
                phaseNumber = 1;
            } else if (phase === 'phase3') {
                phaseNumber = 2;
            } else if (phase === 'phase4') {
                phaseNumber = 3;
            } else if (phase === 'phase5') {
                phaseNumber = 4;
            } else {
                phaseNumber = 5;
            }

            step.comments = new BusinessLineCommentsModel(this.entity, phaseNumber, step.id);
        }

        return step.comments;
    }

    createFieldTrialsTrial = () => {
        const openFieldTrialsWithParams = (() => {
            const owner = this.entity().owner()
            const editor = this.entity().editor()
            const viewers = this.entity().viewers().map(viewer => viewer.email);
            const categories = this.entity().categories().map(category => getDefaultTranslation(category.name_json));
            const innovation_name = this.entity().name();
            
            return (entityId: string) => {
                const params = {
                    innovation_id: entityId,
                    innovation_name,
                    user: session.getEmail(),
                    owner: owner ? owner.email : null,
                    editor: editor ? editor.email : null,
                    viewers,
                    category: categories[0],
                };
                //@ts-ignore
                const fieldtrialsUrl: string = {
                    local: "http://localhost:8080/",
                    qa: "https://fieldtrials-staging.sfsa-tools.org/",
                    production: "https://fieldtrials.sfsa-tools.org/",
                  }[process.env.CONNECT_TO];
                window
                  .open(
                    fieldtrialsUrl +
                      `t/ft/trials/new/?edit_mode=library&gate_params=${encodeURIComponent(
                        JSON.stringify(params)
                      )}`
                  )
                  .focus();
                const currentUrl = window.location.href;
                if (currentUrl.includes("new")) {
                    window.history.pushState({}, "SFSA GATE", currentUrl.replace("new", entityId));
                }
            };
        })();

        this.save(openFieldTrialsWithParams);
    };

    syncFtTrial = async () => {
        this.syncingTrial(true);
        await syncTrialData(this.entity().id());
        this.syncingTrial(false);
        location.reload();
    }
}

export let businessLineEdit = { name: 'business-line-edit', viewModel: createWithComponent(BusinessLineEditScreen), template: template };

ko.components.register(businessLineEdit.name, businessLineEdit);

import * as ko from 'knockout';
import i18n from '../i18n';

import { parseDateTime } from '../api/serialization';

ko.bindingHandlers['timeAgo'] = {
    update: (element: Element, valueAccessor: () => ko.Observable<Date | string | null> | Date | string | null) => {
        let value = ko.unwrap(valueAccessor());

        if (!value) {
            element.textContent = '';
        } else {
            let now = new Date();
            let dateValue: Date | null;
            if (value instanceof Date) {
                dateValue = value;
            } else {
                dateValue = parseDateTime(value);
            }
            if (!dateValue) {
                element.textContent = '';
                return;
            }
            let diffMs = now.getTime() - dateValue.getTime();
            let formatted: string;

            if (diffMs < 0) {
                formatted = '';
            } else {
                let secondsDiff = diffMs / 1000;
                if (secondsDiff <= 44) {
                    formatted = i18n.t('a few seconds ago')();
                } else if (secondsDiff <= 89) {
                    formatted = i18n.t('a minute ago')();
                } else if (secondsDiff <= 44 * 60) {
                    formatted = i18n.t('{{ time }} minutes ago', { time: Math.round(secondsDiff / 60)})();
                } else if (secondsDiff <= 89 * 60) {
                    formatted = i18n.t('an hour ago')();
                } else if (secondsDiff <= 21 * 60 * 60) {
                    formatted = i18n.t('{{ time }} hours ago', { time:  Math.round(secondsDiff / (60 * 60))})();
                } else if (secondsDiff <= 35 * 60 * 60) {
                    formatted = i18n.t('a day ago')();
                } else if (secondsDiff <= 25 * 24 * 60 * 60) {
                    formatted = i18n.t('{{ time }} days ago', { time: Math.round(secondsDiff / (24 * 60 * 60))})();
                } else if (secondsDiff <= 45 * 24 * 60 * 60) {
                    formatted = i18n.t('a month ago')();
                } else if (secondsDiff <= 319 * 24 * 60 * 60) {
                    formatted = i18n.t('{{ time }} months ago', { time: Math.round(secondsDiff / (31 * 24 * 60 * 60))})();
                } else if (secondsDiff <= 547 * 24 * 60 * 60) {
                    formatted = i18n.t('a year ago')();
                } else {
                    formatted = i18n.t('{{ time }} years ago', { time: Math.round(secondsDiff / (365 * 24 * 60 * 60))})();
                }
            }

            element.textContent = formatted;
        }
    }
};

import * as ko from 'knockout';

import { ListRequestParams, RemoveResult } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { SiteData, sitesApi } from '../api/simple_api';
import { BaseLoadingScreen } from '@core/screens/base_loading_screen';
import { canEditSites } from 'permissions';

let template = require('../../templates/sites.html').default;

class SitesScreen extends BaseLoadingScreen implements ListLoaderDelegate<SiteData> {
    fetch(params: ListRequestParams) {
        return sitesApi.list(params);
    }

    instantiate(data: SiteData) {
        return data;
    }

    remove(id: string): Promise<RemoveResult> {
        return sitesApi.remove(id);
    }

    canRemove(data: SiteData) {
        return data.can_edit && canEditSites();
    }

    canAdd() {
        return canEditSites();
    }

    partnerNames(data: SiteData) {
        return data.partners.map(p => p.name).join(', ');
    }
}

export let sites = { name: 'sites', viewModel: SitesScreen, template: template };

ko.components.register(sites.name, sites);

import * as ko from 'knockout';
import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { I18nText, translate } from '../i18n_text';
import { sameIds, BoolDict, tryFormatDate, asArray } from '../utils';
import { createWithComponent, unwrap } from '../utils/ko_utils';

let template = require('../../templates/components/list_filters.html').default;

export class ListFilters {
    filters: (DateFilter | TextFilter | Filter)[];

    constructor(params: { filters: FilterDelegate[], onReady?: (filters: ListFilters) => void }) {
        this.filters = params.filters.map(f =>  {
            if (isDate(f)) {
                return new DateFilter(f.value, f.title);
            } else if (isText(f)) {
                return new TextFilter(f.textValue, f.title);
            } else {
                return new Filter(f);
            }
        });

        if (params.onReady) {
            params.onReady(this);
        }
    }

    dispose() {
        for (let filter of this.filters) {
            filter.dispose();
        }
    }

    updateInitialSelection() {
        for (let filter of this.filters) {
            filter.updateInitialSelections();
        }
    }

    selections = ko.pureComputed(() => {
        let res: Result<{}>[] = [];
        for (let f of this.filters) {
            for (let r of f.results()) {
                if (r.selected()) {
                    res.push(r);
                }
            }
        }

        return res;
    });
}

interface IdData {
    id?: string | ko.Observable<string>;
    name_json?: I18nText;
    name?: string;
}

interface FilterDelegateSearch<T extends IdData> {
    disableSearch?: boolean;
    list: (params: ListRequestParams) => Promise<T[]>;
    entities: ko.ObservableArray<T>;
    title: string;
}

interface FilterDelegateSelect<T extends IdData> {
    choices: T[];
    value: ko.Observable<T>;
    title: string;
}

interface FilterDelegateDate {
    value: ko.Observable<Date>;
    title: string;
}

interface FilterDelegateText {
    textValue: ko.Observable<string>;
    title: string;
}

export type FilterDelegate = FilterDelegateSearch<IdData> | FilterDelegateSelect<IdData> | FilterDelegateDate | FilterDelegateText;

function isSearch(delegate: FilterDelegate): delegate is FilterDelegateSearch<IdData> {
    return !!(<any>delegate).list;
}

function isSelect(delegate: FilterDelegate): delegate is FilterDelegateSelect<IdData> {
    return !!(<any>delegate).choices;
}

function isText(delegate: FilterDelegate): delegate is FilterDelegateText {
    return !!(<any>delegate).textValue;
}

function isDate(delegate: FilterDelegate): delegate is FilterDelegateDate {
    return !isSearch(delegate) && !isSelect(delegate) && !isText(delegate);
}

export function getFilterObservable(delegate: FilterDelegate): ko.Observable<{}> {
    if (isSearch(delegate)) {
        return delegate.entities;
    } else if (isText(delegate)) {
        return delegate.textValue;
    } else {
        return delegate.value;
    }
}

class Result<T extends IdData> {
    name: string;

    constructor(private filter: Filter, public entity: T, selected: boolean) {
        this.name = entity.name_json ? translate(entity.name_json) : entity.name ?? '';
        this.selected(selected);
    }

    selected = ko.observable(false);
    icon = ko.pureComputed(() => {
        if (this.selected()) {
            return this.filter.isMulti() ? 'check_box' : 'radio_button_checked';
        } else {
            return this.filter.isMulti() ? 'check_box_outline_blank' : 'radio_button_unchecked';
        }
    });

    toggle = () => {
        if (this.filter.isMulti()) {
            this.selected(!this.selected());
        } else if (!this.selected()) {
            this.selected(true);
            for (let res of this.filter.results()) {
                if (res !== this && res.selected()) {
                    res.selected(false);
                }
            }
            this.filter.save();
        }
    }

    canRemove = ko.pureComputed(() => this.filter.isMulti());

    remove = () => {
        this.selected(false);
        this.filter.save();
    }
}

class DateFilter {
    isDate = true;
    isText = false;
    results = ko.observable<Result<IdData>[]>([]);

    constructor(public dateValue: ko.Observable<Date>, public title: string) {}

    dispose() {}

    updateInitialSelections() {}
}

class TextFilter {
    isDate = false;
    isText = true;
    results = ko.observable<Result<IdData>[]>([]);

    constructor(public textValue: ko.Observable<string>, public title: string) {}

    dispose() {}

    updateInitialSelections() {}
}

class Filter {
    isDate = false;
    isText = false;

    loading = ko.observable(false);
    isOpen = ko.observable(false);
    searchText = ko.observable('').extend({ rateLimit: 200 });
    lastSearchId = 0;
    hasMoreResults = ko.observable(false);
    private searchResults = ko.observableArray<Result<IdData>>();

    placeholderText = i18n.t('Search')();

    private subscription: ko.Subscription;

    constructor(public delegate: FilterDelegate) {
        this.subscription = this.searchText.subscribe(this.onSearchChanged);
        this.updateInitialSelections();
    }

    updateInitialSelections() {
        if (isSearch(this.delegate)) {
            this.searchResults(this.delegate.entities().map(entity => new Result(this, entity, true)));
        }
    }

    dispose() {
        this.subscription.dispose();
    }

    results: ko.Computed<Result<IdData>[]> = ko.pureComputed(() => {
        if (isSearch(this.delegate)) {
            return this.searchResults();
        } else if (isSelect(this.delegate)) {
            let value = this.delegate.value();
            return this.delegate.choices.map(entity => new Result(this, entity, entity === value));
        } else {
            return [];
        }
    });

    title = ko.pureComputed(() => {
        if (isSelect(this.delegate)) {
            return this.delegate.title + (this.delegate.value() ? ': ' + (this.delegate.value().name ?? '').toLocaleLowerCase() : '');
        } else if (isDate(this.delegate)) {
            return this.delegate.title + (this.delegate.value() ? ': ' + tryFormatDate(this.delegate.value()) : '');
        } else {
            return this.delegate.title;
        }
    });

    isMulti = ko.pureComputed(() => {
        return isSearch(this.delegate);
    })

    canSearch = ko.pureComputed(() => {
        return isSearch(this.delegate) && !this.delegate.disableSearch;
    });

    private onSearchChanged = () => {
        if (!isSearch(this.delegate)) {
            return;
        }

        let term = this.searchText().trim().toLocaleLowerCase();
        let limit = 100;
        let results = this.delegate.list({ offset: 0, limit: limit + 1, search_term: term });

        this.loading(true);

        let lastSearchId = ++this.lastSearchId;
        return results.then((results) => {
            if (this.lastSearchId != lastSearchId) {
                return;
            }

            this.loading(false);

            this.hasMoreResults(results.length > limit);
            results = results.slice(0, limit);
            let known: BoolDict = {};
            let first = this.searchResults().filter(res => res.selected());
            for (let res of first) {
                let id = unwrap(res.entity.id);
                if (id) {
                    known[id] = true;
                }
            }
            results = results.filter(res => !known[<string>res.id]);
            this.searchResults(first.concat(results.map(res => new Result(this, res, false))));
        }).catch(e => {
            this.loading(false);
            throw e;
        });
    }

    anySelected = ko.pureComputed(() => isSearch(this.delegate) ? this.delegate.entities().length > 0 : !!getFilterObservable(this.delegate)());
    icon = ko.pureComputed(() => isSearch(this.delegate) && this.anySelected() ? 'check' : 'keyboard_arrow_down');

    open = () => {
        this.isOpen(true);
        this.onSearchChanged();

        // wait for input to appear, then focus it
        setTimeout(() => {
            $('.search-input > input').focus();
        }, 0);
    }

    close = () => {
        this.isOpen(false);
        this.save();
    }

    save() {
        let selected = this.results().filter(res => res.selected()).map(res => res.entity);
        if (isSearch(this.delegate)) {
            if (!sameIds(this.delegate.entities(), selected)) {
                this.delegate.entities(selected);
            }
        } else {
            let value = getFilterObservable(this.delegate);
            if (value() !== selected[0]) {
                value(selected[0]);
            }
        }
    }
}

ko.components.register('list-filters', { viewModel: createWithComponent(ListFilters), template: template });

export interface Choice {
    id: string;
    name: string;
}

function getYearOptions() {
    let res: Choice[] = [];
    for (let i = 0; i < 10; i++) {
        let year = ((new Date().getFullYear()) - i).toString();
        res.push({ name: year, id: year });
    }

    return res;
}

export const YEAR_OPTIONS = getYearOptions();

export function staticList(options: Choice[]) {
    return (params: ListRequestParams) => {
        let result = options;
        if (params.search_term) {
            result = options.filter(opt => opt.name.toLocaleLowerCase().indexOf(params.search_term) >= 0);
        }
        return Promise.resolve(result);
    };
}

export function filterStaticList(ids: string | string[], lst: Choice[]): Choice[] {
    let idsList = asArray(ids);
    return lst.filter(item => idsList.indexOf(item.id) >= 0);
}

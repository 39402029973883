import { request, ListRequestParams, requestRaw, requestWithQueryParams, ValidationResult, requestWithValidation, listParamsToQueryString } from '@core/api/request';
import { SimpleApi } from '@core/api/simple_api';
import { UserRole } from './check';
import { I18nText } from '@core/i18n_text';
import { FileUploadEndpoint } from '@core/cloud_storage_upload';
import { BarChartConfig } from 'ko_bindings/bar_chart';
import i18n from '@core/i18n';

export interface UserData {
    id: string | null;
    name: string;
    email: string;
    role: UserRole;
    biography: string;
    picture: string;
    picture_url?: string; // read-only
    show_in_dashboard: boolean;
    tags: UserTagData[];
    is_fieldtrials_synced?: boolean; // read-only
}

class UsersApi extends SimpleApi<UserData, { ids?: string[] } & ListRequestParams> {
    constructor() {
        super('users');
    }

    me(): Promise<UserData> {
        return request('GET', '/api/users/me/');
    }

    getPictureUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
        return request('POST', '/api/users/picture_upload_url/', { content_type: contentType });
    }
}

export const usersApi = new UsersApi();

export interface CountryData {
    id: string;
    name: string;
}

export const countriesApi = new SimpleApi<CountryData, { ids?: string[] } & ListRequestParams>('countries');

export interface CropData {
    id: string;
    name_json: I18nText;
}

class CropsApi extends SimpleApi<CropData> {
    constructor() {
        super('crops');
    }

    importFromFieldtrials() {
        return request('POST', '/api/crops/import_from_fieldtrials/');
    }
}

export const cropsApi = new CropsApi();

export interface CropCategoryData {
    id: string;
    name_json: I18nText;
}

export const cropCategoriesApi = new SimpleApi<CropCategoryData>('crop_categories');

export interface FactorDocumentValue {
    id: string;
    user_file_name: string;
    url: string;
}

export type FactorValue = string | FactorDocumentValue | null;

export interface ChecklistData {
    slug: string;
    state: 'approved' | 'declined';
    user_type: 'manager' | 'coordinator';
}

export interface ProductBudgetData {
    id: string | null;
    detail: string;
    unit_period: string;
    number: number | string;
    total_cost: number | string;
    order?: number; // read-only
}

export interface ProductFileData {
    id: string | null;
    phase: number;
    url?: string; // read-only
    file_name: string;
    user_file_name: string;
    caption: string;
    mime_type: string;
    user_name: string;
    created_at: string;
}

export interface ProductFactorDefData {
    id: string | null;
    factor_def: FactorDefData | null;
    order: number;
    benchmark: string;
    market_target: string;
    show_in_summary: boolean;
}

export function getProductDocUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return request('POST', '/api/products/doc_upload_url/', { content_type: contentType });
}

export function getBusinessLineTitleUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return request('POST', '/api/business_lines/title_upload_url/', { content_type: contentType });
}

export type Platform = 'eHUB' | 'CEMA' | 'AE';

export interface BusinessLineSampleDescrData {
    id: string | null;
    order: number;
    test_subject_order: number;
    site: SiteData;
    comment: string;
    size: string;
    unit: string
}

export interface BusinessLineCommentData {
    id: string | null;
    phase: number;
    step: string;
    comment: string;

    client_id: number; // write-only

    // read-only

    user_id?: string;
    user_name?: string;
    created_at?: string; // date
}

export type BusinessLinePhase = 'discard' | 'commercial' | 'pre_commercial' | 'development' | 'designing' | 'definition';

export interface LinkedTrial {
    name: string;
    fieldtrials_trial_id: number;
};

export interface BusinessLineTestSubjectData {
    id: string | null;
    order: number;
    name: string;
    description: string;
    control: boolean;
}

export interface BusinessLinePublicMessageData {
    id: string;
    email: string;
    message: string;
    created_at: string;
}

export interface BusinessLineData {
    id: string | null;
    name: string;
    website: string;
    public: boolean;
    archived: boolean;
    code?: string; // read-only
    country: CountryData | null;
    crops: CropData[];
    categories: ProjectCategoryData[];
    tags: TagData[];
    owner: UserData | null;
    editor: UserData | null;
    supervisor: UserData | null;
    viewers: UserData[];
    dashboard_viewers: UserData[];
    partners: PartnerData[];
    relevant_reason: string;
    goal: string;
    hypothesis: string;
    task_identification: string;
    market_size: string;

    phase_1_changed_at: string | null;
    phase_1_comment: string;

    sample_descriptions: BusinessLineSampleDescrData[];
    test_subjects: BusinessLineTestSubjectData[];
    sources_requirements: string;
    potential_market: string;
    targeted_market: string;
    potential_adoption: number | null;
    potential_adoption_reason: string;
    promoting_equality: string;
    align_framework: string;
    proposed_duration: number | string | null;
    budgets: ProductBudgetData[];
    title_picture: string | null;
    title_picture_url?: string; // read-only

    phase_2_changed_at: string | null;
    phase_2_comment: string;

    developments: BusinessLineDevelopmentData[];

    phase_3_changed_at?: string; // read-only

    fieldtrials_expansion: boolean;
    fieldtrials_expansion_comment: string;
    phase_4_potential_markets: boolean;
    phase_4_potential_markets_comment: string;
    benefits_estimation: string;
    suppliers: string;
    training: string;
    infrastructure: string;
    regulation: string;

    phase_4_changed_at: string | null;
    phase_4_comment: string;

    product_profile: string;
    profile_doc: string | null;
    profile_doc_url?: string; // read-only
    profile_doc_user_file_name: string;
    external_investors: boolean;
    external_investors_comment: string;
    next_evaluation_time_frame: string;

    phase_5_changed_at: string | null;
    phase_5_comment: string;

    discard_changed_at: string | null;
    discard_comment: string;

    checklist: ChecklistData[];
    files: ProductFileData[];
    comments: BusinessLineCommentData[];

    suggested_business_line: { id: string } | null; // write-only

    public_messages?: BusinessLinePublicMessageData[]; // read-only, detail only
    deleted_public_messages: string[]; // write-only

    // read-only
    cur_phase?: BusinessLinePhase;
    cur_phase_perc?: number;
    has_tpp?: boolean;
    tpp_results_chart?: BarChartConfig;
    pre_commercial_productions?: PreCommercialProductionData[];
    commercial_productions?: CommercialProductionData[];
    linked_trials?: LinkedTrial[];

    reduced_crop_loss: string;
    reduced_postharvest_loss: string;
    income_diversification: string;
    use_resources_more_efficiently : string;
    ecosystem_biodiversity : string;
    enhance_soil_health : string;
    reduced_ghg_emissions : string;
    increase_profitability : string;

    reduced_crop_loss_result: string;
    reduced_postharvest_loss_result: string;
    income_diversification_result: string;
    use_resources_more_efficiently_result: string;
    ecosystem_biodiversity_result: string;
    enhance_soil_health_result: string;
    reduced_ghg_emissions_result: string;
    increase_profitability_result: string;

    external_id: string;
}

export interface PreCommercialProductionData {
    cv_name: string;
    early_gen: number;
    registration_phase: string;
}

export interface CommercialProductionData {
    cv_name: string;
    quantity: number;
}

export interface BusinessLineDevelopmentData {
    id: string | null;
    order: number;
    changed_at: string | null;
    end_date: string | null;
    comment: string;
    factors: ProductFactorDefData[];
    files: BusinessLineDevelopmentFileData[];
    records: BusinessLineDevelopmentRecordData[];
}

export interface FactorValuesDict {
    [productFactorId: string]: FactorValue
};

export interface BusinessLineDevelopmentRecordData {
    id: string | null;
    order: number;
    control: boolean;
    factor_values?: FactorValuesDict; // read-only
    factor_values_write?: FactorValue[]; // write-only
    disabled: boolean;
    show_in_summary: boolean;
}

export interface BusinessLineDevelopmentFileData {
    id: string;
    order: number;
    url?: string; // read-only
    file_name: string;
    user_file_name: string;
    mime_type: string;
    user_name: string;
    created_at: string;
}

export type BusinessLineIncludeType = 'all' | 'project_manager' | 'supervisor';

export interface BusinessLineFilters {
    name: string;
    country_ids: string[];
    phases: string[];
    owner_ids: string[];
    category_ids: string[];
    tag_ids: string[];
    modified_after: string;
    created_after: string;
    show_archived: boolean;
    include: BusinessLineIncludeType;
}

interface BusinessLineParams extends ListRequestParams, BusinessLineFilters {
}

export interface BusinessLineListData {
    id: string;
    name: string;
    code?: string; // read-only
    archived: boolean;
    country: CountryData;
    categories: ProjectCategoryData[];
    tags: TagData[];
    owner: UserData;
    supervisor: UserData;
    phase_1_changed_at: string;
    phase_2_changed_at: string;
    has_phase_3: boolean;
    phase_4_changed_at: string;
    phase_5_changed_at: string;
    discard_changed_at: string;
    modified_at: string;
    cur_phase: BusinessLinePhase;
    cur_phase_perc: number;
}

export interface BusinessLineIdsData {
    id: string;
    test_subjects: string[];
    sample_descriptions: string[];
    budgets: string[];
    files: string[];
    comments: { client_id: number; server_id: string; }[];
    developments: { id: string; factors: string[]; files: string[]; records: string[] }[];
}

export interface DashboardFilters {
    category_ids: string[];
    country_ids: string[];
    tag_ids: string[];
    years: string[];
    partner_ids: string[];

}

export interface DashboardData {
    title_line_1: string;
    title_line_2: string;
    subtitle: string;
    description: string;  // this is safe HTML that can be directly injected
    innovations: DashboardInnovationData[];
    locations: DashboardLocationData[];
    categories_chart: DashboardGroupsChartData;
    phases_chart: DashboardGroupsChartData;
    team: {
        picture: string;
        name: string;
        biography: string;
    }[];
}

export interface DashboardGroupsChartData {
    quarters: string[];
    use_category_colors: boolean;
    groups: {
        id: string;
        name: string;
        counts: number[]; // one per quarter
    }[];
}

export interface DashboardLocationData {
    name: string;
    category: { id: string, name: string };
    lat: number;
    lng: number;
    innovations: DashboardInnovationData[];
}

export interface DashboardInnovationData {
    id: string;
    name: string;
    goal: string;
    test_practice_name: string;
    cur_phase: BusinessLinePhase;
    crops: string[];
    tags: string[];
    title_picture_url: string;
    can_view_details: boolean;
    supervisor: {
        name: string;
        picture: string;
    },
    map_url: string;
    has_tpp: boolean;
}

export interface DashboardInnovationDetailsData {
    id: string;
    country: string;
    test_practice_name: string;
    crops: string[];
    partners: {
        name: string,
        website: string,
        country: string,
    }[];
    relevant_reason: string;
    hypothesis: string;
    phases: {
        id: string;
        name: string;
        approved: boolean;
        start_date: string;
        current: boolean;
        perc_length: number;
    }[];
    rounds: {
        // can be factors(average values) or records depending if any specific records are selected
        factors: {
            name: string;
            current_practice: string;
            target: string;
            result: string;
        }[];
        records: {
            number: number;
            name: string;
            factor_values: string[];
        }[];
        factor_names: string[];
    }[];
    precipitations_chart: BarChartConfig;
    temperature_chart: BarChartConfig;
    annex_1_pictures: string[];
    annex_2_pictures: string[];
}

class BusinessLinesApi extends SimpleApi<BusinessLineData, BusinessLineParams, BusinessLineListData, BusinessLineIdsData> {
    constructor() {
        super('business_lines');
    }

    updateChecklist(id: string, checklist: ChecklistData[], checklistSlugs: string[]): Promise<{}> {
        return request('POST', '/api/business_lines/' + id + '/update_checklist/', { checklist, checklist_slugs: checklistSlugs });
    }

    listItem(id: string): Promise<BusinessLineListData> {
        return request('GET', '/api/business_lines/' + id + '/list_item/');
    }

    dashboard(filters: DashboardFilters): Promise<DashboardData> {
        return requestWithQueryParams('GET', '/api/dashboard/', filters);
    }

    dashboardInnovation(id: string): Promise<DashboardInnovationDetailsData> {
        return request('GET', `/api/dashboard/${id}/`);
    }

    sites(innovationId: string, searchTerm: string): Promise<{ id: string, name: string }[]> {
        return request('GET', `/api/dashboard/${innovationId}/sites/?search_term=${encodeURIComponent(searchTerm)}`);
    }

    innovationGeographies(innovationId: string): Promise<{ geographies: string[] }> {
        return request('GET', `/api/business_lines/${innovationId}/geography/`);
    }

    publicReport(id: string): Promise<Blob> {
        return requestRaw('GET', `/api/dashboard/${id}/report/`);
    }

    exportReport(id: string): Promise<Blob> {
        return requestRaw('GET', `/api/business_lines/${id}/report/`);
    }

    exportTPPReport(id: string): Promise<Blob> {
        return requestRaw('GET', `/api/business_lines/${id}/tpp_report/`);
    }

    exportExcelList(filters: BusinessLineFilters): Promise<Blob> {      
        return requestRaw('GET', '/api/business_lines/excel_export/?' + listParamsToQueryString({ ...filters }));
    }

    exportDevelopment(innovationId: string): Promise<Blob> {
        return requestRaw('GET', `/api/business_lines/${innovationId}/development_excel_export/`);
    }

    contact(id: string, params: { email: string, message: string }): Promise<ValidationResult<{}>> {
        return requestWithValidation('POST', `/api/dashboard/${id}/contact/`, params);
    }

    archive(id: string) {
        return request('POST', `/api/business_lines/${id}/archive/`)
    }
}

export const businessLinesApi = new BusinessLinesApi();

export interface ProjectCategoryData {
    id: string | null;
    name_json: I18nText;
    code: string;
    description: string;
    picture: string | null;
    picture_url?: string; // read-only
}

export const projectCategoriesApi = new SimpleApi<ProjectCategoryData, { ids?: string[] } & ListRequestParams>('project_categories');

export function getProjectCategoryPictureUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return request('POST', '/api/project_categories/picture_upload_url/', { content_type: contentType });
}

export interface FactorDefChoiceData {
    id: string | null;
    name_json: I18nText;
}

export type FactorType = 'string' | 'integer' | 'decimal' | 'date' | 'document' | 'choice';

export interface FactorDefData {
    id: string | null;
    name_json: I18nText;
    help_text_json: I18nText;
    category: ProjectCategoryData;
    type: FactorType;
    choices: FactorDefChoiceData[];
    in_use?: boolean; // read-only
}

export const factorDefsApi = new SimpleApi<FactorDefData, { category_ids: string[] } & ListRequestParams>('factor_defs');

export interface PartnerData {
    id: string | null;
    name: string;
    country: CountryData;
    contact: string;
    address: string;
    phone: string;
    email: string;
    website: string;
    responsible: UserData;
    organization_type: OrganizationTypeData;
}

export const partnersApi = new SimpleApi<PartnerData, { ids?: string[] } & ListRequestParams>('partners');

export interface SuggestedBusinessLineData {
    id: string | null;
    user_name: string;
    user_email: string;
    name: string;
    country: CountryData;

    page_in_catalogue: string;
    category: string;
    areas: string;
    tech_companies: string;
    description: string;
    maturity_of_the_technology: string;

    value_proposition: number;
    ease_of_use: number;
    yield_and_income: number;
    affordability: number;
    adoptability: number;
    policy_and_regulations: number;
    improve_resource_use_efficiency: number;
    increase_biodiversity: number;
    improve_soil_health: number;
    reduce_emissions: number;
    resilience: number;

    country_of_technology_origin: string;
    financial_resources: string;
    human_resources: string;
    category_of_revenue_sharing: string;
    tech_test_states: string;
    value_chain: string;

    created_at?: string; // read-only
    promoted_to?: { id: string; name: string; }; // read-only
}

export const SUGGESTED_BUSINESS_LINE_CATEGORIES = [
    {
      title: i18n.t("Trading and post-harvest management")(),
      value: "trading_and_post_harvest",
    },
    {
      title: i18n.t("Diseases management and agricultural advisor")(),
      value: "disease_management",
    },
    { title: i18n.t("Post-harvest quality")(), value: "post_harvest_quality" },
    { title: i18n.t("Irrigation")(), value: "irrigation" },
    { title: i18n.t("Land size")(), value: "land_size" },
    {
      title: i18n.t("Production management and monitoring")(),
      value: "production_management",
    },
    { title: i18n.t("Access to Finance")(), value: "access_to_finance" },
    { title: i18n.t("Others")(), value: "others" },
]

export const SUGGESTED_BUSINESS_LINE_AREAS = [
    { title: i18n.t("Digital")(), value: "digital" },
    { title: i18n.t("Storage")(), value: "storage" },
    { title: i18n.t("Mechanical")(), value: "mechanical" },
    { title: i18n.t("Agronomical practice")(), value: "agronomical_practice" },
    { title: i18n.t("Phytochemical")(), value: "phytochemical" },
    { title: i18n.t("Soil treatments")(), value: "soil_treatments" },
    { title: i18n.t("Weather forecast")(), value: "weather_forecast" },
    { title: i18n.t("Other")(), value: "other" },
]

export const SUGGESTED_BUSINESS_LINE_FINANCIAL_RESOURCES = [
    { title: i18n.t("Full covered by SFSA")(), value: "sfsa" },
    { title: i18n.t("Shared cost of trailing")(), value: "shared" },
    { title: i18n.t("Full covered by the partners")(), value: "partners" },
]

export function isSuggested(data: BusinessLineData | SuggestedBusinessLineData): data is SuggestedBusinessLineData {
    return !!(data as any).user_name;
}

export interface SuggestedBusinessLineParams extends ListRequestParams {
    country_ids: string[];
    category_ids: string[];
    created_after: string;
}

class SuggestedBusinessLinesApi extends SimpleApi<SuggestedBusinessLineData, SuggestedBusinessLineParams> {
    constructor() {
        super('suggested_business_lines');
    }

    exportExcelList(filters: SuggestedBusinessLineParams): Promise<Blob> {      
        return requestRaw('GET', '/api/suggested_business_lines/excel_export/?' + listParamsToQueryString({ ...filters }));
    }
}

export const suggestedBusinessLinesApi = new SuggestedBusinessLinesApi();

export interface HubData {
    id: string | null;
    name: string;
    anonymized_name?: string; // read-only
    franchisor: string;
    country: CountryData | null;
    region: RegionData | null;
    location_lat: number | null;
    location_lon: number | null;
    imported?: boolean; // read-only
}

export const hubsApi = new SimpleApi<HubData, ListRequestParams & { ids?: string[], name: string, countries: string[] }>('hubs');

export interface RegionData {
    id: string | null;
    name: string;
}

export const regionsApi = new SimpleApi<RegionData>('regions');

export interface TagData {
    id: string | null;
    name: string;
}

export const tagsApi = new SimpleApi<TagData, { ids?: string[] } & ListRequestParams>('tags');

export interface UserTagData {
    id: string | null;
    name: string;
}

export const userTagsApi = new SimpleApi<UserTagData>('user_tags');

export interface DashboardSettingsData {
    title_line_1: string;
    title_line_2: string;
    subtitle: string;
    description: string;
}

export function dashboardSettings(): Promise<DashboardSettingsData> {
    return request('GET', '/api/dashboard_settings/');
}

export function updateDashboardSettings(data: DashboardSettingsData): Promise<ValidationResult> {
    return requestWithValidation('POST', '/api/dashboard_settings/', data);
}

export interface OrganizationTypeData {
    id: string;
    name_json: I18nText;
}

export const orgTypesApi = new SimpleApi<OrganizationTypeData>('organization_types');

export interface SiteData {
    id: string;
    name_json: I18nText;
    location_lat: number;
    location_lon: number;
    partners: PartnerData[];
    can_edit?: boolean; // read-only
    access_by_public?: boolean; // read-only
}

export const sitesApi = new SimpleApi<SiteData>('sites');

export interface DocumentationData {
    id: string;
    name: string;
    file_name: string;
    user_file_name: string;
    url?: string; //read-only
}

export const documentationApi = new SimpleApi<DocumentationData>('documentation');

export function getDocumentationUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return request('POST', '/api/documentation/upload_url/', { content_type: contentType });
}

export function syncTrialData(innovationId: string) {
    return request('POST', `/api/business_lines/${innovationId}/sync_trial_data/`)
}
import { session } from '../session';
import { app } from '../app';
import { requestWithStatus, requestRawWithStatus } from './base_request';

export interface ValidationResult<ResultData = { id: string }> {
    isValid: boolean;
    entityId: string | null;
    result: ResultData;
    errors: { [field: string]: [string] };
}

export interface RemoveResult {
    did_delete: boolean;
    dependant_records: { entity_type: string, entities: string[] }[];
}

export interface ListRequestParams {
    offset?: number;
    limit?: number;
    search_term?: string;
    role?: 'viewer' | 'editor' | 'head';
}

function rejectRequest(res: { status: number }) {
    return Promise.reject(new Error(res.status.toString()));
}

function onNetworkError() {
    app.showNetworkError(true);
    return rejectRequest({ status: 0 });
}

function handleErrorResponse(res: { status: number, result: {} }) {
    if (res.status == 403) {
        app.showLoggedOut(true);
    } else if (res.status == 404) {
        app.showEntityNotFound(true);
    } else {
        app.showServerError(true);
    }

    return rejectRequest(res);
}

export function request<ResultType>(method: string, path: string, params?: {}): Promise<ResultType> {
    return session.getToken().then((token) => requestWithStatus<ResultType>(method, path, token, params)).then((res) => {
        if (res.status == 200 || res.status == 201) {
            return res.result;
        } else {
            return handleErrorResponse(res);
        }
    }, onNetworkError);
}

export function requestRaw(method: string, path: string, params?: {}): Promise<Blob> {
    return session.getToken().then((token) => requestRawWithStatus(method, path, token, params)).then(res => {
        if (res.status == 200) {
            return res.data;
        } else {
            return handleErrorResponse({ status: res.status, result: {} });
        }
    }, onNetworkError);
}

export function requestWithValidation<T = {}>(method: string, path: string, params?: {}): Promise<ValidationResult<T>> {
    return requestAnyWithValidation(method, path, params).then((val) => {
        if (val.status == 200 || val.status == 201) {
            return { isValid: true, entityId: val.result.id, result: val.result, errors: {} };
        } else {
            return { isValid: false, entityId: null, result: null, errors: val.result };
        }
    });
}

export function requestAnyWithValidation(method: string, path: string, params?: {}): Promise<{ status: number, result: any }> {
    return session.getToken().then((token) => requestWithStatus<any>(method, path, token, params)).then((val) => {
        if (val.status == 200 || val.status == 201 || val.status == 400) {
            return val;
        }

        return handleErrorResponse(val);
    }, onNetworkError);
}

export function requestRemoveEntity(path: string): Promise<RemoveResult> {
    return session.getToken().then((token) => requestWithStatus<{}>('DELETE', path, token, undefined)).then((val) => {
        if (val.status == 200 || val.status == 204) {
            return { did_delete: true, dependant_records: [] };
        }

        if (val.status == 400) {
            return { did_delete: false, dependant_records: val.result as any };
        }

        return handleErrorResponse(val);
    }, onNetworkError);
}

export function listRequest<Data>(baseURL: string, params: ListRequestParams): Promise<Data[]> {
    return requestWithQueryParams('GET', baseURL, params);
}

export function requestWithQueryParams<Data>(method: string, baseURL: string, params: {}): Promise<Data> {
    let url = baseURL.indexOf('?') === -1 ? baseURL + '?' : baseURL;

    return request(method, url + listParamsToQueryString(params));
}

export function listParamsToQueryString(params: { [key: string]: Object }) {
    let queryString = '';
    let add = (key: string, value: {}) => {
        if (value !== null && value !== undefined) {
            queryString += '&' + key + '=' + encodeURIComponent(value.toString());
        }
    };

    for (let key in params) {
        if (!params.hasOwnProperty(key)) {
            continue;
        }

        let value = params[key];
        if (value instanceof Array) {
            for (let item of value) {
                add(key, item);
            }
        } else {
            add(key, value);
        }
    }

    return queryString;
}

import * as ko from 'knockout';
import i18n from '../i18n';

// override the library default, since it allows '-' as a valid number
ko.validation.rules['number'] = {
    validator: (value: string, validate: boolean) => {
        if (!validate || ko.validation.utils.isEmptyVal(value)) {
            return true;
        }

        return /^-?\d+(?:\.\d+)?$/.test(value);
    },
    message: i18n.t('Please enter a number.')()
};

import * as ko from 'knockout';
import page from 'page';

import * as firebase from 'firebase/app';
import 'firebase/auth';

export class Session {
    private user: firebase.User;

    loggedIn = ko.observable(false);
    userId = ko.observable<string>(null);
    private role: ko.Observable<{} | null> = ko.observable(null);

    authorized = ko.pureComputed(() => {
        return this.loggedIn() && !!this.role();
    });

    onLoggedIn: (state: 'loading' | 'loaded', authorized: boolean) => void;

    init<TRole>(role: ko.Observable<TRole | null>, checkLogin: (token: string, loginAttempt: boolean) => Promise<{ id?: string, role?: TRole }>, firebaseConfig: Object) {
        this.role = role;

        firebase.initializeApp(firebaseConfig);

        let userStateInitialized = false;
        firebase.auth().onAuthStateChanged(async user => {
            this.user = user;

            if (!userStateInitialized && !this.isLoggedIn()) {
                this.loggedIn(false);
                page();
            } else if (this.loggedIn() !== this.isLoggedIn()) {
                this.loggedIn(this.isLoggedIn());
                if (this.loggedIn()) {
                    this.onLoggedIn?.('loading', false);

                    let res = await this.getToken().then(token => checkLogin(token, !!this.onLoggedIn));

                    this.userId(res && res.id ? res.id : null);
                    if (res && res.role) {
                        this.role(res.role);
                    }

                    if (this.onLoggedIn) {
                        this.onLoggedIn('loaded', !!this.role());
                    } else {
                        page();
                    }
                }
            }

            userStateInitialized = true;
        }, err => console.log(err));
    }

    private isLoggedIn() {
        return !!this.user;
    }

    getToken(): Promise<string> {
        if (!this.user) {
            return Promise.resolve('');
        }

        return this.user.getIdToken();
    }

    getEmail(): string | undefined {
        return this.user?.email;
    }

    getName(): string | undefined {
        return this.user?.displayName;
    }

    async logout(): Promise<void> {
        await firebase.auth().signOut();
        this.userId(null);
        this.role(null);
    }
}

export const session = new Session();

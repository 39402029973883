import * as ko from 'knockout';
import i18n from '../i18n';

ko.bindingHandlers['datePicker'] = {
    init: (element: Element, valueAccessor: () => ko.Observable<Date | null>) => {
        let observable = valueAccessor();
        let $picker = $(element).pickadate({
            ...i18n.getDatePickerSettings(),
            selectYears: true,
            selectMonths: true,
            // see https://github.com/amsul/pickadate.js/issues/160
            onClose: () => { if (document.activeElement) { $(document.activeElement).blur(); } }
        });
        let picker = $picker.pickadate('picker');

        $picker.on('change', () => {
            let dateItem = picker.get('select');

            if (dateItem) {
                observable(dateItem.obj);
                picker.close();
            } else {
                observable(null);
                // will auto-close in this case
                picker.$node.blur();
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $picker.off('change');
            picker.stop();
        });
    },
    update: (element: Element, valueAccessor: () => ko.Observable<Date>) => {
        let observable = valueAccessor();
        let $picker = $(element).pickadate();
        let picker = $picker.pickadate('picker');

        picker.set('select', observable());
    }
};

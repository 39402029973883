import * as ko from 'knockout';

import i18n from '@core/i18n';
import { UserData, usersApi, UserTagData } from '../api/simple_api';
import { UserRole } from '../api/check';
import { ImageUpload } from '@core/image_upload';
import { getUserTagSearch } from './helpers/search_configs';

export class User {
    roleOptions: { title: string, value: UserRole }[] = [
        { title: i18n.t('Admin')(), value: 'admin' },
        { title: i18n.t('Editor')(), value: 'user' },
        { title: i18n.t('User')(), value: 'restricted_user' }
    ];

    id = ko.observable<string | null>(null);
    name = ko.observable('').extend({
        required: true
    });
    email = ko.observable('').extend({
        required: true,
        serverError: true
    });
    role = ko.observable<UserRole>('user');
    biography = ko.observable('');
    showInDashboard = ko.observable(false);
    tags = ko.observableArray<UserTagData>();
    isFieldtrialsSynced = ko.observable(false);

    editUrl = ko.pureComputed(() => {
        return '/users/' + this.id();
    });

    roleTitle = ko.pureComputed(() => {
        for (let item of this.roleOptions) {
            if (item.value === this.role()) {
                return item.title;
            }
        }

        return '';
    });

    pictureUpload = new ImageUpload({
        getImageUploadEndpoint: usersApi.getPictureUploadEndpoint
    });

    tagsSearch = getUserTagSearch(this.tags);

    tagNames = ko.pureComputed(() => this.tags().map(tag => tag.name).join(', '));

    constructor(data?: UserData) {
        if (data) {
            this.id(data.id);
            this.name(data.name);
            this.email(data.email);
            this.role(data.role);
            this.biography(data.biography);
            this.pictureUpload.fileName = data.picture;
            this.pictureUpload.picturePublicURL(data.picture_url);
            this.showInDashboard(data.show_in_dashboard);
            this.tags(data.tags);
            this.isFieldtrialsSynced(data.is_fieldtrials_synced);
        }
    }

    toData(): UserData {
        return {
            id: this.id(),
            name: this.name(),
            email: this.email(),
            role: this.role(),
            biography: this.biography(),
            picture: this.pictureUpload.fileName || null,
            show_in_dashboard: this.showInDashboard(),
            tags: this.tags()
        };
    }
}

import * as ko from 'knockout';

import { PartnerData, partnersApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { Partner } from '../models/partner';

let template = require('../../templates/partner_edit.html').default;

class PartnerEditScreen extends BaseEditScreen<PartnerData, Partner> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<PartnerData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: partnersApi, focus: true }, params, componentInfo);
    }

    instantiate(data: PartnerData) {
        return new Partner(data);
    }
}

export let partnerEdit = { name: 'partner-edit', viewModel: createWithComponent(PartnerEditScreen), template: template };

ko.components.register(partnerEdit.name, partnerEdit);

import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';

let template = require('../../templates/components/forms_stack.html').default;

export class FormsStackController {
    forms = ko.observableArray<{ isBig?: boolean, showNav?: boolean }>();

    push<T, TP>(args: { title: string, name: string, showNav?: boolean, isBig?: boolean, params: { result: Deferred<T> } & TP }): Promise<T> {
        this.forms.push(args);

        return args.params.result.promise.then(
            (entity) => {
                this.pop();
                return entity;
            },
            () => {
                this.pop();
                return Promise.reject(null);
            }
        );
    }

    clear() {
        this.forms.removeAll();
    }

    pop() {
        this.forms.pop();
    }
}

class FormsStack {
    controller: FormsStackController;

    constructor(params: { controller: FormsStackController }) {
        this.controller = params.controller;
    }

    isLastFormBig = ko.pureComputed(() => {
        let len = this.controller.forms().length;
        return len > 0 && this.controller.forms()[len - 1].isBig;
    });

    showNav = ko.pureComputed(() => {
        let forms = this.controller.forms();
        return forms.length > 1 || (forms.length === 1 && forms[0].showNav);
    });
}

ko.components.register('forms-stack', { viewModel: FormsStack, template: template });

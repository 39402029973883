import * as ko from 'knockout';
import page from 'page';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { SuggestedBusinessLineData, suggestedBusinessLinesApi, CountryData, countriesApi, SUGGESTED_BUSINESS_LINE_CATEGORIES, SUGGESTED_BUSINESS_LINE_AREAS, SUGGESTED_BUSINESS_LINE_FINANCIAL_RESOURCES } from '../api/simple_api';
import { ListHeaderAction } from '@core/components/list_header';
import i18n from '@core/i18n';
import { Action } from '@core/components/basic_widgets';
import { deflateList, updateLocationWithQueryString, asArray, floatWithPrecision } from '@core/utils';
import { serializeDate, parseDate } from '@core/api/serialization';
import { BaseLoadingScreen } from '@core/screens/base_loading_screen';
import { SuggestedBusinessLine } from 'models/suggested_business_line';
import { app } from '@core/app';
import { Deferred } from '@core/utils/deferred';
import { excelExporter } from 'components/excel_exporter';

let template = require('../../templates/suggested_business_lines.html').default;

interface FilterParams {
    country_ids: string | string[];
    category_ids: string | string[];
    created_after: string;
}

function listCategories(params: ListRequestParams) {
    return Promise.resolve(
        SUGGESTED_BUSINESS_LINE_CATEGORIES.filter(
        (cat) =>
          cat.value &&
          cat.title
            .toLocaleLowerCase()
            .indexOf(params.search_term.toLocaleLowerCase()) > -1
      ).map(cat => ({id: cat.value, name: cat.title}))
    )
  }

class SuggestedBusinessLinesScreen extends BaseLoadingScreen implements ListLoaderDelegate<SuggestedBusinessLineData> {
    private countryFilters = ko.observableArray<CountryData>();
    private categoryFilters = ko.observableArray<{id: string, title: string}>();
    private createdFilter = ko.observable<Date>();

    newFilters = [
        { list: countriesApi.list.bind(countriesApi), entities: this.countryFilters, title: i18n.t('Country')() },
        { list: listCategories, entities: this.categoryFilters, title: i18n.t('Categories')() },
        { value: this.createdFilter, title: i18n.t('Created after')() }
    ];

    listActions: ListHeaderAction[] = [];

    constructor(params: { filters: FilterParams }) {
        super();

        let countryIds = asArray(params.filters.country_ids);
        let categoryIds = asArray(params.filters.category_ids);

        let countryPromise =
        countryIds.length > 0
          ? countriesApi.list({ ids: countryIds })
          : Promise.resolve<CountryData[]>([]);
      
        let categoryPromise =
        categoryIds.length > 0
          ? Promise.resolve(
            SUGGESTED_BUSINESS_LINE_CATEGORIES.filter((cat) =>
                categoryIds.some((id) => id === cat.value)
              ).map(cat => ({ id: cat.value, name: cat.title }))
            )
          : Promise.resolve([]);

        let promise = Promise.all([countryPromise, categoryPromise]).then(([countries, categories]) => {
            this.countryFilters(countries);
            this.categoryFilters(categories);
            this.createdFilter(parseDate(params.filters.created_after));
        });
        this.loadedAfter(promise);
    }

    fetch(params: ListRequestParams) {
        let filters = this.getFilters();
        updateLocationWithQueryString(filters);

        return suggestedBusinessLinesApi.list({ ...params, ...filters });
    }

    instantiate(data: SuggestedBusinessLineData) {
        return data;
    }

    getEditUrl(entity: SuggestedBusinessLineData): string {
        return entity.promoted_to ? null : '/suggested_innovations/' + entity.id + '/';
    }

    getFilters() {
        return {
            country_ids: deflateList(this.countryFilters),
            category_ids: deflateList(this.categoryFilters),
            created_after: serializeDate(this.createdFilter())
        };
    }

    excelExport() {
        app.formsStackController.push({
            title: i18n.t('Preparing Excel file')(),
            name: excelExporter.name,
            params: { 
                filters: this.getFilters(), 
                result: new Deferred<{}>(),
                exportExcel: suggestedBusinessLinesApi.exportExcelList,
                fileName: 'suggested_innovations.xlsx'
            },
            showNav: true
        });
    }
    excelExportBound = this.excelExport.bind(this);

    remove(id: string) {
        return suggestedBusinessLinesApi.remove(id);
    }

    canRemove(data: SuggestedBusinessLineData) {
        return true;
    }

    getActions(entity: SuggestedBusinessLineData): Action[] {
        if (entity.promoted_to) {
            return [];
        }

        return [
            {
                icon: 'thumb_up',
                title: i18n.t('Promote')(),
                cssClass: '',
                onClick: () => page('/business_line/promote/' + entity.id + '/')
            }
        ];
    }

    getCategories(entity: SuggestedBusinessLineData): string {
        return SUGGESTED_BUSINESS_LINE_CATEGORIES.find(cat => cat.value === entity.category).title;
    }

    getArea(entity: SuggestedBusinessLineData): string {
        return SUGGESTED_BUSINESS_LINE_AREAS.find(area => area.value === entity.areas).title;
    }

    getFinancialResources(entity: SuggestedBusinessLineData): string {
        return SUGGESTED_BUSINESS_LINE_FINANCIAL_RESOURCES.find(res => res.value === entity.financial_resources).title;
    }

    getAverageCSRA(entity: SuggestedBusinessLineData): number {
        return floatWithPrecision(new SuggestedBusinessLine(entity).averageCSRA(), 2);
    }

    getSumFinal(entity: SuggestedBusinessLineData): number {
        return floatWithPrecision(new SuggestedBusinessLine(entity).sumFinal(), 2);
    }

    getScore(entity: SuggestedBusinessLineData): number {
        return floatWithPrecision(new SuggestedBusinessLine(entity).score(), 2);
    }
}

export let suggestedBusinessLines = { name: 'suggested-business-lines', viewModel: SuggestedBusinessLinesScreen, template: template };

ko.components.register(suggestedBusinessLines.name, suggestedBusinessLines);

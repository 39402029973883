import * as ko from 'knockout';
import { DashboardInnovationData, DashboardLocationData } from 'api/simple_api';
import i18n from '@core/i18n';
import { escape } from '@core/utils';

declare const google: any;

let map: any;
let markers: any[] = [];
let currentLocations: DashboardLocationData[] = null;
let popup: any;
let currentOverlay = 'none';

const colors = [
    '#3182bd',
    '#9ecae1',
    '#e6550d',
    '#fdae6b',
    '#31a354',
    '#a1d99b',
    '#756bb1',
    '#6baed6',
    '#c6dbef',
    '#fd8d3c',
    '#fdd0a2',
    '#74c476',
    '#c7e9c0',
    '#9e9ac8',
    '#dadaeb',
];

export class ColorAssignment {
    private assignedColors: { [key: string]: string } = {};
    private nextColor = 0;

    getColor(id: string): string {
        if (!this.assignedColors[id]) {
            this.assignedColors[id] = colors[this.nextColor % colors.length];
            this.nextColor++;
        }
        return this.assignedColors[id];
    }
}

export const categoryColors = new ColorAssignment();

function asSVGData(svg: string): string {
    return `data:image/svg+xml;base64,${btoa(svg)}`;
}

function pieSize(cluster: LocationCluster, maxLocations: number): number {
    const maxSize = 64;
    const minSize = 32;
    const oneSize = 24;

    const nLocations = countLocations(cluster);

    if (nLocations === 1) {
        return oneSize;
    }

    const size = (nLocations / maxLocations) * (maxSize - minSize) + minSize;
    return Math.max(minSize, Math.min(maxSize, size));
}

function pieSVG(cluster: LocationCluster, size: number): string {
    const step = (Math.PI * 2) / cluster.categoryIds.length;

    const paths: string[] = [];
    let angle = -Math.PI / 2;
    for (let categoryId of cluster.categoryIds) {
        const color = categoryColors.getColor(categoryId);

        const xStart = Math.cos(angle) * 0.975;
        const yStart = Math.sin(angle) * 0.975;

        angle += step;

        let xEnd = Math.cos(angle) * 0.975;
        const yEnd = Math.sin(angle) * 0.975;

        if (Math.abs(xEnd) < 0.000001) {
            // avoid issue with chrome not filling the circle when there's only 1 category
            xEnd = -0.000001;
        }

        paths.push(`<path d="M ${xStart} ${yStart} A 0.975 0.975 0 ${step > Math.PI ? 1 : 0} 1 ${xEnd} ${yEnd} L 0 0" fill="${color}"/>`);
    }

    return asSVGData(`
    <svg xmlns="http://www.w3.org/2000/svg" height="${size}" width="${size}" viewBox="0 0 2 2">
        <g transform="translate(1 1)">
            ${paths.join(' ')}
        </g>
        <circle cx="1" cy="1" r="0.975" stroke="#000" stroke-width="0.05" fill="none"/>
    </svg>
    `);
}

interface LocationCluster {
    lat: number;
    lng: number;
    categoryIds: string[];
    locations: DashboardLocationData[];
}

function removeOverlaps(locations: DashboardLocationData[]): LocationCluster[] {
    if (!map.getProjection()) {
        return locations.map(loc => ({ lat: loc.lat, lng: loc.lng, categoryIds: [loc.category.id], locations: [loc] }));
    }

    const minPxDistance = 24;
    const res: LocationCluster[] = [];

    const added = new Set<DashboardLocationData>();
    for (let origin of locations) {
        if (added.has(origin)) {
            continue;
        }

        const cluster = [origin];
        added.add(origin);

        for (let other of locations) {
            if (added.has(other)) {
                continue;
            }

            if (pxDistance(origin, other) < minPxDistance) {
                cluster.push(other);
                added.add(other);
            }
        }

        if (cluster.length === 1) {
            res.push({ lat: origin.lat, lng: origin.lng, categoryIds: [origin.category.id], locations: [origin] });
        } else {
            let centerLat = 0;
            let centerLng = 0;
            for (let loc of cluster) {
                centerLat += loc.lat;
                centerLng += loc.lng;
            }
            centerLat /= cluster.length;
            centerLng /= cluster.length;

            const categories = new Set<string>();
            for (let loc of cluster) {
                categories.add(loc.category.id);
            }
            const categoryIds: string[] = [];
            categories.forEach(cat => categoryIds.push(cat));

            res.push({ lat: centerLat, lng: centerLng, categoryIds: categoryIds, locations: cluster });
        }
    }

    return res;
}

function pxDistance(loc1: DashboardLocationData, loc2: DashboardLocationData): number {
    const p1 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(loc1));
    const p2 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(loc2));

    return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / mapPxSize();
}

function mapPxSize(): number {
    return Math.pow(2, -map.getZoom());
}

function redraw() {
    if (currentLocations !== null) {
        resetMarkers(currentLocations);
    }
}

function countLocations(cluster: LocationCluster) {
    const seen: { lat: number, lng: number, name: string }[] = [];

    let count = 0;
    for (let loc of cluster.locations) {
        const exists = seen.some(other => other.name === loc.name && Math.abs(other.lat - loc.lat) < 0.001 && Math.abs(other.lng - loc.lng) < 0.001);
        if (!exists) {
            seen.push({ lat: loc.lat, lng: loc.lng, name: loc.name });
            count++;
        }
    }

    return count;
}

function resetMarkers(locations: DashboardLocationData[]): LocationCluster[] {
    currentLocations = locations;

    for (let marker of markers) {
        google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
    }
    markers = [];
    const rendered = removeOverlaps(locations);
    let maxLocations = 1;
    for (let location of rendered) {
        maxLocations = Math.max(maxLocations, countLocations(location));
    }
    for (let location of rendered) {
        const size = pieSize(location, maxLocations);
        let marker = new google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map,
            icon: { url: pieSVG(location, size), anchor: new google.maps.Point(size / 2, size / 2) }
        });
        markers.push(marker);

        google.maps.event.addListener(marker, 'click', () => {
            showInfo(marker, location);
        });
    }

    return rendered;
}

function showInfo(marker: any, data: LocationCluster) {
    let content = '';

    if (data.locations.length === 1) {
        content += `<div class="map-popup-title">${escape(data.locations[0].name)}</div>`;
    }

    const innovationSites = new Map<string, Set<string>>();
    const innovations: DashboardInnovationData[] = [];
    for (let loc of data.locations) {
        for (let innovation of loc.innovations) {
            if (!innovationSites.has(innovation.id)) {
                innovationSites.set(innovation.id, new Set());
                innovations.push(innovation);
            }
            innovationSites.get(innovation.id).add(loc.name);
        }
    }

    for (let innovation of innovations) {
        let siteNames: string[] = [];
        innovationSites.get(innovation.id).forEach(name => siteNames.push(name));

        let lines = [
            { title: i18n.t('Sites')(), value: siteNames.join(', ') },
            { title: i18n.t('Objective')(), value: innovation.goal || '—' },
            { title: i18n.t('Description')(), value: innovation.test_practice_name || '—' },
            { title: i18n.t('Phase')(), value: innovation.cur_phase },
            { title: i18n.t('Crops')(), value: innovation.crops.length > 0 ? innovation.crops.join(', ') : '—' },
            { title: i18n.t('Tags')(), value: innovation.tags.length > 0 ? innovation.tags.join(', ') : '—' }
        ];

        let linesHTML = lines.map(
            line => `<div><span class="map-popup-line-title">${escape(line.title)}:</span> <span>${escape(line.value)}</span></div>`
        ).join('');

        const categoryFilters = data.locations.map(loc => `category_ids=${loc.category.id}`).join('&');

        content += `
        <i class="map-popup-icon material-icons">info</i>
        <a class="map-popup-link" href="/dashboard_details/?${categoryFilters}&innovation_id=${innovation.id}">${escape(innovation.name)}</a>
        <div class="map-popup-info">${linesHTML}</div>
        <div class="map-popup-divider"></div>`;
    }

    popup.setContent(content);
    popup.open(map, marker);
}

function fitAll(locations: LocationCluster[]) {
    let hasPoints = false;
    let bounds = new google.maps.LatLngBounds();
    for (let point of locations) {
        bounds.extend({ lat: point.lat, lng: point.lng });
        hasPoints = true;
    }
    if (hasPoints) {
        map.fitBounds(bounds);
    } else {
        map.setCenter({ lat: 47.535, lng: 7.578 });
        map.setZoom(8);
    }
}

async function getCountry(location: { lat: number, lng: number}): Promise<{ location: any, bounds: any } | null> {
    const geocoder = new google.maps.Geocoder();

    return new Promise(resolve => {
        geocoder.geocode({ location }, (results: any) => {
            if (!results) {
                resolve(null);
                return;
            }

            for (let result of results) {
                for (let cType of result.types) {
                    if (cType === 'country') {
                        resolve({ location: result.geometry.location, bounds: result.geometry.bounds });
                        return;
                    }
                }
            }
            resolve(null);
        });
    });
}

class CallbackQueue {
    private cbs: (() => void)[] = [];

    constructor(public isReady: () => boolean) {
        this.checkReady();
    }

    private checkReady = () => {
        if (this.isReady()) {
            for (let cb of this.cbs) {
                try {
                    cb();
                } catch (e) {
                    setTimeout(() => { throw e; }, 0);
                }
            }
            this.cbs = [];
        } else {
            setTimeout(this.checkReady, 100);
        }
    };

    run(cb: () => void) {
        if (this.isReady()) {
            cb();
        } else {
            this.cbs.push(cb);
        }
    }

    clear() {
        this.cbs = [];
    }
}

const googleMapsReady = new CallbackQueue(() => !!(window as any).google);

ko.bindingHandlers['innovationGeographies'] = {
    update: (element: Element, valueAccessor: () => { geographies: string[] }) => {        
        googleMapsReady.run(() => {
            const geographies = valueAccessor().geographies;
            map.data.forEach((feature: any) => map.data.remove(feature));
            for(const geography of geographies) {
                map.data.addGeoJson({
                    type: 'Feature',
                    geometry: JSON.parse(geography) 
                })
                map.overlayMapTypes.clear();
            }
            if(geographies.length === 0) {
                if (currentOverlay === "gyga_ted_ssa") {
                    showGygaSsaTiles()
                }
            }
        })
    }
}

ko.bindingHandlers['overlay'] = {
    update: (element: Element, valueAccessor: () => { overlay: string }) => {        
        googleMapsReady.run(() => {
            const overlay = valueAccessor().overlay;
            if (overlay !== currentOverlay) {
                map.overlayMapTypes.clear();
            } else {
                return;
            }
            currentOverlay = overlay;
            if (overlay === "gyga_ted_ssa") {
                showGygaSsaTiles()
            }
        })
    }
}

const showGygaSsaTiles = () => {
    const tiles = new google.maps.ImageMapType({
        getTileUrl: function (coord: { x : number, y: number }, zoom: number) {
          const normalizedCoord = getNormalizedCoord(coord, zoom);
          const bound = Math.pow(2, zoom);
          if (!normalizedCoord) return "";
          return `/api/files/public/?name=tiles/${zoom}/${
            normalizedCoord.x
          }/${bound - normalizedCoord.y - 1}.png`;
        },
        tileSize: new google.maps.Size(256, 256),
        opacity: 0.4,
      });
      map.overlayMapTypes.push(tiles);
}

ko.bindingHandlers['dashboardMap'] = {
    init: (element: Element) => {
        const mapElem = document.getElementById('dashboard-map');
        if (!mapElem) {
            return;
        }

        const legendElem = document.createElement('div');
        legendElem.className = 'dashboard-map-legend';

        const legendTitle = document.createElement('div');
        legendTitle.className = 'dashboard-map-legend-title';
        legendTitle.textContent = i18n.t('Categories')();

        const legendItems = document.createElement('div');
        legendItems.className = 'dashboard-map-legend-items';

        legendElem.appendChild(legendTitle);
        legendElem.appendChild(legendItems);

        ko.cleanNode(mapElem);
        element.appendChild(mapElem);

        googleMapsReady.run(() => {
            if (!map) {
                map = new google.maps.Map(mapElem, {
                    mapTypeId: 'terrain',
                    tilt: 0, // a 45 deg tilt shows polylines in the wrong place
                    zoomControl: true,
                    disableDoubleClickZoom: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    maxZoom: 9,
                });

                let requestNumber = 0;
                map.addListener('click', async (evt: any) => {
                    const currentRequest = ++requestNumber;

                    const country = await getCountry(evt.latLng);
                    if (requestNumber !== currentRequest) {
                        return;
                    }
                    if (country) {
                        map.setCenter(country.location);
                        map.fitBounds(country.bounds);
                    }
                });
                map.addListener('zoom_changed', redraw);

                popup = new google.maps.InfoWindow({
                    content: '',
                    pixelOffset: 0,
                    maxWidth: 300,
                });
            }

            for (let control of map.controls) {
                if (control) {
                    control.clear();
                }
            }

            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(legendElem);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if (googleMapsReady.isReady()) {
                resetMarkers([]);
            }
            googleMapsReady.clear();
            document.getElementById('dashboard-map-container')?.appendChild(mapElem);
        });
    },

    update: (element: Element, valueAccessor: () => { locations: ko.ObservableArray<DashboardLocationData> }) => {
        googleMapsReady.run(() => {
            let params = valueAccessor();
            let points = ko.unwrap(params.locations);
            let rendered = resetMarkers(points);

            const legendElem: HTMLElement = map.controls[google.maps.ControlPosition.RIGHT_TOP].getAt(0);
            const legendItems = legendElem.querySelector('.dashboard-map-legend-items');
            legendItems.innerHTML = '';

            const categories: { id: string, name: string }[] = [];
            const seen = new Set<string>();
            for (let loc of points) {
                if (!seen.has(loc.category.id)) {
                    categories.push(loc.category);
                    seen.add(loc.category.id);
                }
            }
            for (let cat of categories) {
                const item = document.createElement('div');
                const box = document.createElement('div');
                const label = document.createElement('div');

                box.style.backgroundColor = categoryColors.getColor(cat.id);
                label.textContent = cat.name;

                item.appendChild(box);
                item.appendChild(label);

                legendItems.appendChild(item);
            }

            fitAll(rendered);
        });
    }
};

// Normalizes the coords that tiles repeat across the x axis (horizontally)
// like the standard Google map tiles.
const getNormalizedCoord = (coord: { x: number; y: number }, zoom: number) => {
    const y = coord.y;
    let x = coord.x;
  
    // tile range in one direction range is dependent on zoom level
    // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
    const tileRange = 1 << zoom;
  
    // don't repeat across y-axis (vertically)
    if (y < 0 || y >= tileRange) {
      return null;
    }
  
    // repeat across x-axis
    if (x < 0 || x >= tileRange) {
      x = ((x % tileRange) + tileRange) % tileRange;
    }
  
    return { x: x, y: y };
  };
import * as ko from 'knockout';

import { UserTagData, userTagsApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { UserTag } from 'models/user_tag';

let template = require('../../templates/user_tag_edit.html').default;

class UserTagEditScreen extends BaseEditScreen<UserTagData, UserTag> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<UserTagData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: userTagsApi, focus: true }, params, componentInfo);
    }

    instantiate(data: UserTagData) {
        return new UserTag(data);
    }
}

export let userTagEdit = { name: 'user-tag-edit', viewModel: createWithComponent(UserTagEditScreen), template: template };

ko.components.register(userTagEdit.name, userTagEdit);

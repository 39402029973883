import * as ko from "knockout";
import { Deferred } from "@core/utils/deferred";

import { ImportEntitiesDelegate } from "./import_entities";
import { businessLinesApi } from "../api/simple_api";
import i18n from "@core/i18n";

const template =
  require("../../templates/components/import_entities_container.html").default;

class ImportDevelopmentDialog implements ImportEntitiesDelegate {
  loading?: ko.Observable<boolean>;
  title = ko.observable("Import Development Records");
  description = i18n.t(
    "To import development records, download the Excel template, fill-in the desired records and then upload the file."
  )();
  backTitle = "";
  templateBaseName = "development_records";
  isDialog = true;
  private result: Deferred<{} | null>;
  private innovationId: ko.Observable<string>;

  constructor(params: {
    innovationId: ko.Observable<string>;
    result: Deferred<{} | null>;
  }) {
    this.result = params.result;
    this.innovationId = params.innovationId;
  }

  downloadTemplate(): Promise<Blob> {
    return businessLinesApi.exportDevelopment(this.innovationId());
  }

  getImportUrl(): string {
    return `/api/business_lines/${this.innovationId()}/development_excel_import/`;
  }

  onClose = () => {
    this.result.resolve(null);
  };

  onSuccess = () => {
    location.reload();
  };
}

export const importDevelopmentDialog = {
  name: "import-development",
  viewModel: ImportDevelopmentDialog,
  template: template,
};

ko.components.register(importDevelopmentDialog.name, importDevelopmentDialog);

import * as ko from "knockout";
import { GeoJSON } from "../ko_bindings/map_select";
import { Deferred } from "../utils/deferred";
import { createWithComponent } from "../utils/ko_utils";
import { app } from "../app";
import i18n from "../i18n";
import { FormSelectSearchConfiguration } from "./form_select_search";
import { PlaceLocationDict } from "./basic_widgets";
import { translate } from "../i18n_text";

let template = require("../../templates/components/map_select.html").default;

class MapSelect {
  result: Deferred<{} | null> | undefined;
  obs: KnockoutObservable<any>;
  config: FormSelectSearchConfiguration<PlaceLocationDict>;
  points: KnockoutObservableArray<GeoJSON>;
  loading = ko.observable(false);

  constructor(params: {
    obs: KnockoutObservable<any>;
    result?: Deferred<{} | null>;
    searchConfig: FormSelectSearchConfiguration<PlaceLocationDict>;
  }) {
    this.result = params.result;
    this.obs = params.obs;
    this.config = params.searchConfig;
    delete this.config.create;
    this.points = ko.observableArray([]);
    this.loadPoints(params.searchConfig);
  }

  onClose = () => {
    this.result?.resolve(null);
  };

  loadPoints = async (
    config: FormSelectSearchConfiguration<PlaceLocationDict>
  ) => {
    this.loading(true);
    const list = await config.list({});
    const geoJSON: GeoJSON[] = list
      .filter((res) => res.location_lat && res.location_lon)
      .map((res) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [res.location_lat, res.location_lon],
        },
        properties: {
          title: translate(res.name_json),
          value: res,
          id: res.id.toString(),
        },
      }));
    this.points.removeAll();
    this.points.push(...geoJSON);
    this.loading(false);
  };
}

ko.components.register("map-select", {
  viewModel: createWithComponent(MapSelect),
  template: template,
});

export function mapSelectPopup(
  obs: KnockoutObservable<any>,
  searchConfig: FormSelectSearchConfiguration<PlaceLocationDict>
) {
  app.formsStackController.push({
    title: i18n.t("Select location")(),
    name: "map-select",
    isBig: true,
    params: {
      obs,
      searchConfig,
      result: new Deferred<GeoJSON | null>(),
    },
  });
}

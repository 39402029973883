import * as ko from 'knockout';

import { TagData, tagsApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { Tag } from 'models/tag';

let template = require('../../templates/tag_edit.html').default;

class TagEditScreen extends BaseEditScreen<TagData, Tag> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<TagData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: tagsApi, focus: true }, params, componentInfo);
    }

    instantiate(data: TagData) {
        return new Tag(data);
    }
}

export let tagEdit = { name: 'tag-edit', viewModel: createWithComponent(TagEditScreen), template: template };

ko.components.register(tagEdit.name, tagEdit);

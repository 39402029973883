import * as ko from 'knockout';
import i18n from '../i18n';
import page from 'page';

import { MaybeKO, asObservable, createWithComponent } from '../utils/ko_utils';
import { session } from '../session';
import { I18nText, translate } from '../i18n_text';
import { selectLocationPopup } from './map';
import { Point } from '../ko_bindings/map_location';
import { showNeedPageReload } from '../api/base_request';
import { NotificationData, NotificationItemData } from '../api/base_notifications';
import { mapSelectPopup } from './map_select';
import { FormSelectSearchConfiguration } from './form_select_search';

let navigationBarTemplate = require('../../templates/components/basic_widgets/navigation_bar.html').default;
let popupTemplate = require('../../templates/components/basic_widgets/popup.html').default;
let loadingIndicatorTemplate = require('../../templates/components/basic_widgets/loading_indicator.html').default;
let infoTemplate = require('../../templates/components/basic_widgets/info.html').default;
let actionsMenuTemplate = require('../../templates/components/basic_widgets/actions_menu.html').default;
let dropdownTemplate = require('../../templates/components/basic_widgets/dropdown.html').default;

let primaryButtonTemplate = require('../../templates/components/basic_widgets/primary_button.html').default;
let addButtonTemplate = require('../../templates/components/basic_widgets/add_button.html').default;
let saveButtonTemplate = require('../../templates/components/basic_widgets/save_button.html').default;
let secondaryButtonTemplate = require('../../templates/components/basic_widgets/secondary_button.html').default;
let flatButtonTemplate = require('../../templates/components/basic_widgets/flat_button.html').default;

let formTextInputTemplate = require('../../templates/components/basic_widgets/form_text_input.html').default;
let formTextAreaTemplate = require('../../templates/components/basic_widgets/form_textarea.html').default;
let formCheckboxTemplate = require('../../templates/components/basic_widgets/form_checkbox.html').default;
let formSelectTemplate = require('../../templates/components/basic_widgets/form_select.html').default;
let formDateInputTemplate = require('../../templates/components/basic_widgets/form_date_input.html').default;
let fileUploadTemplate = require('../../templates/components/basic_widgets/file_upload.html').default;
let formLocationMapTemplate = require('../../templates/components/basic_widgets/form_location_map.html').default;
let formSelectMapTemplate = require("../../templates/components/basic_widgets/form_map_select.html").default;

class LoadingIndicator {
    text: ko.Observable<string>;

    constructor(params: { text: ko.Observable<string> }) {
        this.text = params.text || ko.observable(i18n.t('Loading...')());
    }
}

export interface Action {
    icon: string;
    title: string;
    cssClass: string;
    onClick: () => void;
}

class ActionsMenu {
    isOpen = ko.observable(false);
    actions: MaybeKO<Action[]>;
    title: string | undefined;

    constructor(params: { actions: MaybeKO<Action[]>, title?: string }) {
        this.actions = params.actions;
        this.title = params.title;
    }

    onActionClick = (action: Action) => {
        this.isOpen(false);
        action.onClick();
    }

    // workaround for IE, which focuses child element instead of flat-button
    onFocusIn = () => {
        this.isOpen(true);
    }

    onFocusOut = () => {
        this.isOpen(false);
    }
}

export class FormValueInput<T = {}> {
    htmlId = ko.observable<string>('');
    value: ko.Observable<T | null>;
    enable: ko.Observable<boolean>;
    showValidation: ko.Observable<boolean>;

    subscriptions: ko.Subscription[] = [];

    constructor(params: { value: ko.Observable<T | null>, enable?: MaybeKO<boolean>, showValidation?: MaybeKO<boolean> }, componentInfo: ko.components.ComponentInfo) {
        let element = <Element>componentInfo.element;

        this.htmlId(element.getAttribute('input-id') || ('id-' + Math.floor(Math.random() * 1000000000)));
        this.value = params.value;
        this.enable = params.enable === undefined ? ko.observable(true) : asObservable(params.enable);
        this.showValidation = params.showValidation === undefined ? ko.observable(true) : asObservable(params.showValidation);

        this.subscriptions.push(this.value.subscribe(this.onValueChanged));
    }

    dispose() {
        this.subscriptions.forEach(sub => sub.dispose());
    }

    onValueChanged = () => {
        if (this.value.serverError) {
            this.value.serverError(null);
        }
    }

    static createViewModel(params: { value: ko.Observable<{} | null>, enable?: ko.Observable<boolean> }, componentInfo: ko.components.ComponentInfo) {
        return new FormValueInput(params, componentInfo);
    }
}

interface Dict {
    [key: string]: string | number;
}

interface FormSelectInputParams {
    value: ko.Observable<string | number>;
    options: ko.ObservableArray<Dict> | Dict[];
    enable?: ko.Observable<boolean>;
    optionsText: MaybeKO<string>;
    optionsValue: MaybeKO<string>;
}

class FormSelectInput extends FormValueInput {
    options: ko.ObservableArray<Dict>;
    optionsText: string | ((item: {}) => string | I18nText);
    optionsValue: MaybeKO<string>;

    constructor(params: FormSelectInputParams, componentInfo: ko.components.ComponentInfo) {
        super(params, componentInfo);

        if (ko.isObservable(params.options)) {
            this.options = <ko.ObservableArray<Dict>>params.options;
        } else {
            this.options = ko.observableArray<Dict>(<Dict[]>params.options);
        }
        this.optionsText = params.optionsText;
        this.optionsValue = params.optionsValue;

        // sync model with UI
        this.subscriptions.push(
            this.options.subscribe(options => {
                if (this.value() === null && options.length) {
                    this.value(options[0][ko.unwrap(this.optionsValue)]);
                } else if (!options.length) {
                    this.value(null);
                }
            })
        );
    }

    trOptionsText = (item: { [key: string]: MaybeKO<string | I18nText> }) => {
        if (!this.optionsText) {
            return item;
        }

        let value: string | I18nText;

        if (typeof this.optionsText === 'string') {
            value = ko.unwrap(item[this.optionsText]);
        } else {
            value = this.optionsText(item);
        }

        return typeof value === 'string' ? value : translate(value);
    }

    static createViewModel(params: FormSelectInputParams, componentInfo: ko.components.ComponentInfo) {
        return new FormSelectInput(params, componentInfo);
    }
}

class Dropdown {
    htmlId = ko.observable<string>('');
    title: MaybeKO<string>
    options: ko.ObservableArray<{}>;
    click: (item: {}) => void;

    constructor(params: { title: MaybeKO<string>, options: ko.ObservableArray<{}>, click: (item: {}) => void }, componentInfo: ko.components.ComponentInfo) {
        let element = <Element>componentInfo.element;

        this.htmlId(element.getAttribute('dropdown-id') || ('dropdown-id-' + Math.floor(Math.random() * 1000000000)));
        this.title = params.title;
        this.options = params.options;
        this.click = params.click;
    }

    static createViewModel(params: { title: string, options: ko.ObservableArray<{}>, click: (item: {}) => void }, componentInfo: ko.components.ComponentInfo) {
        return new Dropdown(params, componentInfo);
    }
}

export interface FileUploadDelegate {
    fileUploadError?: ko.Observable<boolean>;
    picturePublicURL?: ko.Observable<string | null>;
    onFileContents(userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest): void;
}

class FileUpload {
    icon: string;
    accept: string;
    delegate: FileUploadDelegate;
    enable: ko.Observable<boolean>;

    fileUploadPercentage = ko.observable<number | null>(null);
    fileUploadError = ko.observable<string | null>('');

    constructor(params: { icon: string; accept: string; delegate: FileUploadDelegate; enable?: MaybeKO<boolean>; }) {
        this.icon = params.icon;
        this.accept = params.accept;
        this.delegate = params.delegate;
        this.enable = params.enable === undefined ? ko.observable(true) : asObservable(params.enable);
    }

    private prepareXHR = () => {
        let xhr = $.ajaxSettings.xhr!();
        xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
            let percent = Math.ceil(event.loaded / event.total * 100);
            this.fileUploadPercentage(percent);
        });
        xhr.upload.addEventListener('load', () => {
            this.fileUploadPercentage(null);
        });
        xhr.upload.addEventListener('error', () => {
            this.fileUploadPercentage(null);
        });

        return xhr;
    }

    onFileSelected = (data: {}, evt: Event) => {
        let input = <HTMLInputElement>evt.target;
        if (!input.files) {
            return;
        }
        let file = input.files[0];
        if (!file) {
            return;
        }
        let contentType = file.type || 'application/octet-stream';

        this.fileUploadError(null);

        input.value = ''; // force change event to trigger again if user re-selectes the same file

        let fileContentsPromise = new Promise<string>((accept, reject) => {
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = (evt: ProgressEvent) => {
                accept(<string>(<any>evt).target.result);
            };

            reader.onabort = reader.onerror = () => {
                reject();
            };
        });

        fileContentsPromise.then(fileContents => {
            this.delegate.onFileContents(file.name, fileContents, contentType, this.prepareXHR);
        });
    }
}

export class FormLocationMap extends FormValueInput<Point> {
    onSelectLocation = () => {
        selectLocationPopup(this.value, this.others ? this.others() : [], this.enable());
    }

    private others: ko.ObservableArray<Point>;
    locationPos: ko.Observable<string>;

    constructor(params: { value: ko.Observable<Point>, others?: ko.ObservableArray<Point>, locationPos?: ko.Observable<string>, enable?: MaybeKO<boolean>, }, componentInfo: ko.components.ComponentInfo) {
        super(params, componentInfo);

        this.others = params.others;
        this.locationPos = params.locationPos || FormLocationMap.posObservable();

        let changing = false;
        let onValueChanged = (value: Point | null) => {
            if (changing) {
                return;
            }

            changing = true;
            if (value) {
                this.locationPos(value.lat + ', ' + value.lng);
            } else {
                this.locationPos('');
            }
            changing = false;
        };

        this.subscriptions.push(this.value.subscribe(onValueChanged));
        this.subscriptions.push(this.locationPos.subscribe(value => {
            if (changing) {
                return;
            }

            changing = true;
            if (this.locationPos.isValid()) {
                value = value.trim();
                if (value) {
                    let [lat, lng] = value.split(',', 2);
                    this.value({ lat: parseFloat(lat), lng: parseFloat(lng) });
                } else {
                    this.value(null);
                }
            }
            changing = false;
        }));

        onValueChanged(this.value());
    }

    static createViewModel(params: { value: ko.Observable<Point>, enable?: ko.Observable<boolean> }, componentInfo: ko.components.ComponentInfo) {
        return new FormLocationMap(params, componentInfo);
    }

    static posObservable(): ko.Observable<string> {
        return ko.observable('').extend({ geoPt: true });
    }
}

class PrimaryButton {
    disabled: ko.Observable<boolean>;

    constructor(params: { disabled: ko.Observable<boolean> }) {
        this.disabled = params.disabled || ko.observable(false);
    }
}

class AddButton extends PrimaryButton {
}

class FlatButton {
    icon: MaybeKO<string>;

    constructor(params: { icon: MaybeKO<string> }) {
        this.icon = params.icon;
    }
}

class SaveButton {
    saving: ko.Observable<boolean>;

    constructor(params: { saving: ko.Observable<boolean> }) {
        this.saving = params.saving;
    }
}

export class NavGroup {
    expanded = ko.observable(false);

    constructor(public title: string, public items: NavItem[]) {
    }
}

export interface NavItem {
    title: string;
    icon: string;
    href: string;
    important?: boolean;
}

export interface NotificationsDelegate {
    onOpenNotifications(): void;
    fetchNotifications(): Promise<NotificationData>;
}

class NavigationBar {
    private dismissed = ko.observable(false);
    isVerticalMenuOpen = ko.observable(false);
    title: string;
    groups: NavGroup[] = [];
    banner: string | undefined;
    userName: string | undefined;

    showNeedPageReload = showNeedPageReload;

    private notificationsDelegate?: NotificationsDelegate;
    notifications = ko.observableArray<NotificationItemData>();
    unreadNotifications = ko.observable(0);
    showNotifications = ko.observable(false);

    constructor(params: { title: string, groups: MaybeKO<NavGroup[]>, notificationsDelegate?: NotificationsDelegate, banner?: string }) {
        this.title = params.title;
        this.groups = ko.unwrap(params.groups);
        this.notificationsDelegate = params.notificationsDelegate;
        this.banner = params.banner;

        let name = session.getName() || '';
        let email = session.getEmail() || '';
        this.userName = name && email ? `${name} (${email})` : (name || email);

        this.notificationsDelegate?.fetchNotifications().then(data => {
            this.notifications(data.notifications);
            this.unreadNotifications(data.unread);
        });
    }

    toggle = (toggled: NavGroup) => {
        for (let group of this.groups) {
            group.expanded(group === toggled && !toggled.expanded());
        }
    }

    dismiss = () => {
        this.dismissed(true);
    };

    openVerticalMenu = () => {
        this.isVerticalMenuOpen(true);

        return false; // stop anchor link
    }

    closeVerticalMenu = () => {
        this.isVerticalMenuOpen(false);
    }

    selected = () => {
        this.isVerticalMenuOpen(false);
        return true; // allow children to handle click event
    }

    reload = () => {
        page.stop();
        location.href = location.href;
    }

    toggleNotifications = () => {
        this.showNotifications(!this.showNotifications());
        if (this.showNotifications()) {
            this.unreadNotifications(0);
            this.notificationsDelegate?.onOpenNotifications();
        }
    };
}

export interface PlaceLocationDict {
  id: string | ko.Observable<string>;
  name_json: I18nText;
  location_lat: number | null;
  location_lon: number | null;
}

interface FormMapSelectInputParams {
  value: KnockoutObservable<PlaceLocationDict>;
  config: FormSelectSearchConfiguration<PlaceLocationDict>;
  enable?: KnockoutObservable<boolean>;
}

export class FormSelectMap extends FormValueInput {
  config: FormSelectSearchConfiguration<PlaceLocationDict>;
  value: KnockoutObservable<PlaceLocationDict>
  formFieldText: ko.PureComputed

  onSelect = () => { 
    mapSelectPopup(this.value, this.config);
  };

  onRemove = () => {
      this.value(null);
  }

  constructor(params: FormMapSelectInputParams, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    super(params, componentInfo);   
    this.config = params.config;
    this.value = params.value;
    this.formFieldText = ko.pureComputed(() => this.value && this.value() ? translate(this.value().name_json) : i18n.t('Select')());
  }

  static createViewModel(params: FormMapSelectInputParams, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    return new FormSelectMap(params, componentInfo);
  }
}

ko.components.register('navigation-bar', { viewModel: createWithComponent(NavigationBar), template: navigationBarTemplate });
ko.components.register('popup', { template: popupTemplate });
ko.components.register('loading-indicator', { viewModel: createWithComponent(LoadingIndicator), template: loadingIndicatorTemplate });
ko.components.register('info', { template: infoTemplate });
ko.components.register('actions-menu', { viewModel: createWithComponent(ActionsMenu), template: actionsMenuTemplate });
ko.components.register('dropdown', { viewModel: { createViewModel: Dropdown.createViewModel }, template: dropdownTemplate });

ko.components.register('primary-button', { viewModel: createWithComponent(PrimaryButton), template: primaryButtonTemplate });
ko.components.register('add-button', { viewModel: createWithComponent(AddButton), template: addButtonTemplate });
ko.components.register('secondary-button', { template: secondaryButtonTemplate });
ko.components.register('flat-button', { viewModel: createWithComponent(FlatButton), template: flatButtonTemplate });
ko.components.register('save-button', { viewModel: createWithComponent(SaveButton), template: saveButtonTemplate });

ko.components.register('form-text-input', { viewModel: { createViewModel: FormValueInput.createViewModel }, template: formTextInputTemplate });
ko.components.register('form-textarea', { viewModel: { createViewModel: FormValueInput.createViewModel }, template: formTextAreaTemplate });
ko.components.register('form-checkbox', { viewModel: { createViewModel: FormValueInput.createViewModel }, template: formCheckboxTemplate });
ko.components.register('form-select', { viewModel: { createViewModel: FormSelectInput.createViewModel }, template: formSelectTemplate });
ko.components.register('form-date-input', { viewModel: { createViewModel: FormValueInput.createViewModel }, template: formDateInputTemplate });
ko.components.register('file-upload', { viewModel: createWithComponent(FileUpload), template: fileUploadTemplate });
ko.components.register('form-location-map', { viewModel: { createViewModel: FormLocationMap.createViewModel }, template: formLocationMapTemplate });
ko.components.register("form-select-map", { viewModel: { createViewModel: FormSelectMap.createViewModel }, template: formSelectMapTemplate });

import { userRole } from "./app_session";

export function canEditFactorDefs() {
  return userRole() !== "restricted_user";
}

export function canEditRegions() {
  return userRole() !== "restricted_user";
}

export function canEditSites() {
  return userRole() !== "restricted_user";
}

export function canEditPartners() {
  return userRole() !== "restricted_user";
}

export function canEditCrops() {
  return userRole() !== "restricted_user";
}

export function canEditTags() {
  return userRole() !== "restricted_user";
}

export function canAddDocumentation() {
  return userRole() === "admin";
}

export function canEditDocumentation() {
  return userRole() === "admin";
}

export function canRemoveDocumentation() {
  return userRole() === "admin";
}

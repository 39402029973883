import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { businessLinesApi, countriesApi, CountryData, UserData, usersApi, ProjectCategoryData, projectCategoriesApi, BusinessLineListData, BusinessLineFilters, BusinessLinePhase } from '../api/simple_api';
import { translate } from '@core/i18n_text';
import i18n from '@core/i18n';
import { deflateList, updateLocationWithQueryString, asArray, readFromLocalStorage } from '@core/utils';
import { serializeDate, parseDate } from '@core/api/serialization';
import { BaseLoadingScreen } from '@core/screens/base_loading_screen';
import { Action } from '@core/components/basic_widgets';
import { app } from '@core/app';
import { businessLineDownloadReport } from 'components/business_line_download_report';
import { Deferred } from '@core/utils/deferred';
import { excelExporter } from 'components/excel_exporter';

let template = require('../../templates/business_lines.html').default;

export const PHASES: { name: string; id: BusinessLinePhase }[] = [
    { name: i18n.t('Definition')(), id: 'definition' },
    { name: i18n.t('Designing')(), id: 'designing' },
    { name: i18n.t('Development')(), id: 'development' },
    { name: i18n.t('Pre-commercial')(), id: 'pre_commercial' },
    { name: i18n.t('Commercial')(), id: 'commercial' },
    { name: i18n.t('Learnings')(), id: 'discard' }
];

export function getPhaseName(phaseId: BusinessLinePhase) {
    for (let phase of PHASES) {
        if (phase.id === phaseId) {
            return phase.name;
        }
    }

    return '';
}

function listPhases(params: ListRequestParams) {
    return Promise.resolve(PHASES.filter(phase => phase.name.toLocaleLowerCase().indexOf(params.search_term.toLocaleLowerCase()) > -1));
}

interface FilterParams {
    country_ids: string | string[];
    phases: string | string[];
    owner_ids: string | string[];
    category_ids: string | string[];
    modified_after: string;
}

class BusinessLinesScreen extends BaseLoadingScreen implements ListLoaderDelegate<BusinessLineListData> {
    private filtersKey = 'business-lines-filters-v1'; // update when format is not compatible with existing saved formats
    private showArchivedChoices = [
        { name: i18n.t('No')(), id: 'false' },
        { name: i18n.t('Yes')(), id: 'true' },
    ];

    private countryFilters = ko.observableArray<CountryData>();
    private phaseFilters = ko.observableArray<{ name: string; id: string }>();
    private ownerFilters = ko.observableArray<UserData>();
    private categoryFilters = ko.observableArray<ProjectCategoryData>();
    private modifiedFilter = ko.observable<Date>();
    private showArchived = ko.observable(this.showArchivedChoices[0]);


    newFilters = [
        { list: countriesApi.list.bind(countriesApi), entities: this.countryFilters, title: i18n.t('Country')() },
        { list: listPhases, entities: this.phaseFilters, title: i18n.t('Phase')() },
        { list: usersApi.list.bind(usersApi), entities: this.ownerFilters, title: i18n.t('Project manager')() },
        { list: projectCategoriesApi.list.bind(projectCategoriesApi), entities: this.categoryFilters, title: i18n.t('Categories')() },
        { value: this.modifiedFilter, title: i18n.t('Modified after')() },
        { value: this.showArchived, choices: this.showArchivedChoices, title: i18n.t('Show archived')() },
    ];

    constructor(params: { filters: FilterParams }) {
        super();

        if (!params.filters.category_ids && !params.filters.country_ids && !params.filters.modified_after && !params.filters.owner_ids && !params.filters.phases) {
            params.filters = readFromLocalStorage(this.filtersKey);
        }

        let countryIds = asArray(params.filters.country_ids);
        let phases = asArray(params.filters.phases);
        let ownerIds = asArray(params.filters.owner_ids);
        let categoryIds = asArray(params.filters.category_ids);

        let countryPromise = countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);
        let ownerPromise = ownerIds.length > 0 ? usersApi.list({ ids: ownerIds }) : Promise.resolve<UserData[]>([]);
        let categoryPromise = categoryIds.length > 0 ? projectCategoriesApi.list({ ids: categoryIds }) : Promise.resolve<ProjectCategoryData[]>([]);

        let promise = Promise.all([countryPromise, ownerPromise, categoryPromise]).then(([countries, owners, categories]) => {
            this.countryFilters(countries);
            this.phaseFilters(PHASES.filter(phase => phases.indexOf(phase.id) > -1));
            this.ownerFilters(owners as UserData[]); // type-cast is a workaround for https://github.com/microsoft/TypeScript/pull/34501
            this.categoryFilters(categories);
            this.modifiedFilter(parseDate(params.filters.modified_after));
        });
        this.loadedAfter(promise);
    }

    getFilters(): BusinessLineFilters {
        return {
            name: null,
            country_ids: deflateList(this.countryFilters),
            phases: deflateList(this.phaseFilters),
            owner_ids: deflateList(this.ownerFilters),
            category_ids: deflateList(this.categoryFilters),
            tag_ids: [],
            modified_after: serializeDate(this.modifiedFilter()),
            created_after: null,
            show_archived: this.showArchived().id === 'true',
            include: 'all' as 'all'
        };
    }

    fetch(params: ListRequestParams) {
        let filters = this.getFilters()
        updateLocationWithQueryString(filters);
        window.localStorage.setItem(this.filtersKey, JSON.stringify(filters));

        return businessLinesApi.list({ ...params, ...filters });
    }

    instantiate(data: BusinessLineListData) {
        return data;
    }

    excelExport() {     
        app.formsStackController.push({
            title: i18n.t('Preparing Excel file')(),
            name: excelExporter.name,
            params: { 
                filters: this.getFilters(), 
                result: new Deferred<{}>(),
                exportExcel: businessLinesApi.exportExcelList,
                fileName: 'innovations.xlsx'
            },
            showNav: true
        });
    }
    excelExportBound = this.excelExport.bind(this)

    getActions(entity: BusinessLineListData): Action[] {
        return [
            {
                icon: 'file_download',
                title: i18n.t('Report')(),
                cssClass: '',
                onClick: () => {
                    app.formsStackController.push({
                        title: i18n.t('Preparing report')(),
                        name: businessLineDownloadReport.name,
                        params: { id: entity.id, name: entity.name, result: new Deferred<{}>() },
                        showNav: true
                    });
                }
            }
        ];
    }

    archive(id: string) {
        return businessLinesApi.archive(id);
    }

    canArchive(entity: BusinessLineListData) {
        return !entity.archived;
    }

    includesArchived() {
        return this.showArchived().id === 'true';
    }

    getEditUrl(entity: BusinessLineListData): string {
        return '/innovations/' + entity.id + '/';
    }

    getName(entity: BusinessLineListData): string {
        return entity.name + '/' + entity.country.name;
    }

    getPhaseName(entity: BusinessLineListData) {
        return getPhaseName(entity.cur_phase);
    }

    getCategories(entity: BusinessLineListData): string {
        return entity.categories.map(cat => translate(cat.name_json)).join(', ');
    }

    remove(id: string) {
        return businessLinesApi.remove(id);
    }

    canRemove(data: BusinessLineListData) {
        return true;
    }
}

export let businessLines = { name: 'business-lines', viewModel: BusinessLinesScreen, template: template };

ko.components.register(businessLines.name, businessLines);

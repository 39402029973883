import * as ko from 'knockout';

(<any>ko.extenders).serverError = (target: ko.Observable<{}>, option: {}) => {
    target.serverError = ko.observable<string | null>(null);
    return target;
};

ko.bindingHandlers['validationError'] = {
    update: function (element: Element, valueAccessor: () => ko.Observable<{}>) {
        let error = getErrorFromValueAccessor(valueAccessor);

        for (let child of <Array<Element>>Array.prototype.slice.call(element.childNodes)) {
            if (child.tagName == 'INPUT') {
                if (error.showError) {
                    if (!child.classList.contains('invalid')) {
                        child.classList.add('invalid');
                        child.classList.remove('valid');
                    }
                } else {
                    if (!child.classList.contains('valid')) {
                        child.classList.remove('invalid');
                        if (error.isModified) {
                            child.classList.add('valid');
                        }
                    }
                }
            }

            if (child.tagName == 'LABEL') {
                if (error.showError && error.errorMessage) {
                    child.setAttribute('data-error', error.errorMessage);
                } else {
                    child.removeAttribute('data-error');
                }
            }
        }
    }
};

ko.bindingHandlers['validationErrorText'] = {
    update: function (element: HTMLElement, valueAccessor: () => ko.Observable<{}>) {
        let error = getErrorFromValueAccessor(valueAccessor);

        if (error.showError) {
            element.style.display = 'block';
            element.textContent = error.errorMessage;
        } else {
            element.style.display = 'none';
            element.textContent = '';
        }
    }
};

function getErrorFromValueAccessor(valueAccessor: () => ko.Observable<{}>) {
    let obsv = valueAccessor();
    if (!obsv) {
        return { isModified: false, showError: false, errorMessage: null };
    }

    let serverError = obsv.serverError ? obsv.serverError() : null;
    let isModified = (!!serverError) || (obsv.isModified && obsv.isModified());
    let isValid = !serverError && obsv.isValid && obsv.isValid();
    let showError = isModified && !isValid;

    return {
        isModified: isModified,
        showError: showError,
        errorMessage: (obsv.error && obsv.error()) || serverError
    }
}

import i18n from '../i18n';
import * as ko from 'knockout';

import { FormValueInput } from './basic_widgets';
let formDateTimeInputTemplate = require('../../templates/components/basic_widgets/form_date_time_input.html').default;

class FormDateTimeInput extends FormValueInput<Date> {
    date = ko.observable<Date | null>(null);
    hours = ko.observable<string | null>(null).extend({
        digit: true,
        min: 0,
        max: 23
    });
    minutes = ko.observable<string | null>(null).extend({
        digit: true,
        min: 0,
        max: 59
    });
    subscriptions: ko.Subscription[] = [];

    constructor(params: { value: ko.Observable<Date | null> }, componentInfo: ko.components.ComponentInfo) {
        super(params, componentInfo);

        if (! params.value || ! params.value.setError) {
            throw new Error('You must pass a validatable observable to form-date-time-input');
        }

        let safeHours = (val: string | null) => {
            let hours = val === null ? 0 : +val || 0;
            return Math.min(Math.max(0, hours), 23);
        }

        let safeMinutes = (val: string | null) => {
            let minutes = val === null ? 0 : +val || 0;
            return Math.min(Math.max(0, minutes), 59);
        }

        let updateValue = () => {
            let newDate: Date | null;
            let day = this.date();

            if (!(this.hours.isValid() && this.minutes.isValid())) {
                this.value.setError(i18n.t('Invalid time')());
                return;
            }

            if (!day) {
                newDate = null;
            } else {
                newDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), safeHours(this.hours()), safeMinutes(this.minutes()));
            }

            let oldDate = <Date>this.value();

            if (!oldDate && newDate) {
                this.value(newDate);
            } else if (oldDate && !newDate) {
                this.value(null);
            } else if (newDate && oldDate && oldDate.getTime() != newDate.getTime()) {
                this.value(newDate);
            }
        }

        let updateDate = (newDate: Date | null) => {
            this.date(newDate || null);
            if (newDate) {
                this.hours('' + newDate.getHours());
                this.minutes('' + newDate.getMinutes());
            } else {
                this.hours(null);
                this.minutes(null);
            }
        };

        this.subscriptions.push(this.hours.subscribe(updateValue));
        this.subscriptions.push(this.minutes.subscribe(updateValue));
        this.subscriptions.push(this.date.subscribe(updateValue));

        this.subscriptions.push(this.value.subscribe(updateDate));

        updateDate(<Date>this.value());
    }

    dispose() {
        for (let sub of this.subscriptions) {
            sub.dispose();
        }
    }

    static createViewModel(params: { value: ko.Observable<Date | null> }, componentInfo: ko.components.ComponentInfo) {
        return new FormDateTimeInput(params, componentInfo);
    }
}

ko.components.register('form-date-time-input', { viewModel: { createViewModel: FormDateTimeInput.createViewModel }, template: formDateTimeInputTemplate });

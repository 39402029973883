import * as ko from 'knockout';
import page from 'page';

import i18n from './i18n';
import { parseDate, parseDateTime } from './api/serialization';
import { listParamsToQueryString } from './api/request';
import { pad } from './utils/strings';
import { unwrap } from './utils/ko_utils';

export function tryFormatDate(date: string | Date): string | undefined {
    let parsedDate = asDate(date);

    if (parsedDate === null) {
        return <string>date;
    }

    let day = parsedDate.getDate();
    let monthIndex = parsedDate.getMonth();
    let year = parsedDate.getFullYear();

    let dateInfo = i18n.getDatePickerSettings();
    let translatedMonth = dateInfo?.['monthsFull']?.[monthIndex];

    return dateInfo?.format?.replace('yyyy', String(year)).replace('mmmm', translatedMonth ?? '').replace('dd', String(day));
}

export function tryFormatDateTime(date: string | Date): string {
    let parsedDate = asDateTime(date);

    if (parsedDate === null) {
        return <string>date;
    }

    let datePart = tryFormatDate(parsedDate);

    if (parsedDate && datePart) {
        return datePart + ' ' + pad(parsedDate.getHours()) + ':' + pad(parsedDate.getMinutes());
    } else {
        return '';
    }
}

function asDate(date: string | Date): Date | null {
    if (typeof date === 'number') {
        return null;
    } else if (typeof date === 'string') {
        try {
            return parseDate(date);
        } catch (_) {
            return null;
        }
    } else {
        return date;
    }
}

function asDateTime(date: string | Date): Date | null {
    if (typeof date === 'number') {
        return null;
    } else if (typeof date === 'string') {
        try {
            return parseDateTime(date);
        } catch (_) {
            return null;
        }
    } else {
        return date;
    }
}

export function isIE11() {
    return !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

export function downloadBlob(blob: Blob, name: string) {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, name);
    } else {
        let url = URL.createObjectURL(blob);
        downloadURI(url, name);
        URL.revokeObjectURL(url);
    }
}

export function downloadURI(uri: string, name: string) {
    let evt = new MouseEvent('click', { view: window, bubbles: false, cancelable: true });
    let a = document.createElement('a');
    a.setAttribute('download', name);
    a.setAttribute('href', uri);
    a.setAttribute('target', '_blank');
    a.dispatchEvent(evt);
}

const escapeElem = document.createElement('div');

export function escape(text: string): string {
    escapeElem.innerText = text;
    return escapeElem.innerHTML;
}

export function readDecimal(value: number | string): string {
    if (value === null || value === undefined) {
        return '';
    }

    // strip trailing 0s
    return parseFloat(value.toString()).toString()
}

export function emptyToNull(value: string): string | null {
    if (value === '') {
        return null;
    }

    return value;
}

export function all(vals: boolean[]) {
    for (let val of vals) {
        if (!val) {
            return false;
        }
    }
    return true;
}

export function currentYear(): string {
    return new Date().getFullYear().toString();
}

export function extractId(value: ko.Observable<{ id: string }>): string | null {
    return value() ? value().id : null;
}

export function deflateList(entities: ko.ObservableArray<{ id: string }>) {
    return entities().map(entity => ko.unwrap(entity.id));
}

export function updateLocationWithFilters(data: {}) {
    let queryString = listParamsToQueryString(data);
    let newPath = queryString === '' ? location.pathname :  location.pathname + '?' + queryString;

    if ((location.origin + newPath) != location.href) {
        (<any>page).show(newPath, null, false);
    }
}

export function refreshObservableArrayItem<T>(array: ko.ObservableArray<T>, item: T) {
    let idx = array.indexOf(item);
    if (idx >= 0) {
        array.splice(idx, 1);
        array.splice(idx, 0, item);
    }
}

export function findById<T extends { id: string | null }>(opts: T[], id: string): T | null {
    if (id === null || id === undefined) {
        return null;
    }

    for (let opt of opts) {
        if (opt.id === id) {
            return opt;
        }
    }

    return null;
}

export type BoolDict = { [key: string]: boolean };

export function sameIds(v1: ({ id?: string | ko.Observable<string> })[], v2: ({ id?: string | ko.Observable<string> })[]): boolean {
    let v1Ids = v1.map(e => unwrap(e.id));
    let v2Ids = v2.map(e => unwrap(e.id));

    if (v1Ids.length === v2Ids.length) {
        v1Ids.sort();
        v2Ids.sort();

        for (let i = 0; i < v1Ids.length; i++) {
            if (v1Ids[i] !== v2Ids[i]) {
                return false;
            }
        }
    } else {
        return false;
    }

    return true;
}

export function asArray<T>(item: T | T[]): T[] {
    if (!item) {
        return [];
    }

    if (Array.isArray(item)) {
        return item;
    }

    return [item];
}

export function updateLocationWithQueryString(params: {}) {
    let qs = listParamsToQueryString(params);
    let newPath = qs ? location.pathname + '?' + qs : location.pathname;
    if ((location.origin + newPath) != location.href) {
        (<any>page).replace(newPath, null, undefined, false);
    }
}

export const MONTH_OPTIONS = (i18n.getDatePickerSettings().monthsFull ?? []).map((name, idx) => ({ name, value: idx + 1 }));

export function toDict<T, V>(items: T[], fn: (item: T, idx: number) => [string, V]): { [key: string]: V } {
    let res: { [key: string]: V } = {};
    let i = 0;
    for (let item of items) {
        let [key, value] = fn(item, i++);
        res[key] = value;
    }

    return res;
}

function isReallyNaN(a: any) {
    // NaN is the only value which is both equal and different to itself
    // note that we can't use isNaN, since isNaN({}) === true
    return !(a === a) && (a !== a);
}

export function deepEquals(a: any, b: any) {
    if (a === b || (isReallyNaN(a) && isReallyNaN(b))) {
        return true;
    }

    if (a === null || a === undefined || b === null || b === undefined) {
        return false;
    }

    if (Object.prototype.toString.call(a) === '[object Array]'
        && Object.prototype.toString.call(b) === '[object Array]') {
        if (a.length === b.length) {
            for (let i = 0; i < a.length; i++) {
                if (!deepEquals(a[i], b[i])) {
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }

    if (Object.prototype.toString.call(a) === '[object Object]'
        && Object.prototype.toString.call(b) === '[object Object]') {
        let aKeys: BoolDict = {};

        for (let k in a) {
            aKeys[k] = true;

            if (!deepEquals(a[k], b[k])) {
                return false;
            }
        }

        for (let k in b) {
            if (!aKeys[k]) {
                return false;
            }
        }

        return true;
    }

    return false;
}

export function readFromLocalStorage<T>(key: string) {
    let res = window.localStorage.getItem(key);
    if (!res) {
        return {} as T;
    }
    try {
        return JSON.parse(res);
    } catch {
        return {} as T;
    }
}

export function floatWithPrecision(float: number, precision: number) {
    return parseFloat(float.toFixed(precision));
}
import * as ko from 'knockout';
import * as i18n from '@core/i18n'; i18n;

// force to import files that are used for global side effects (e.g. registering a KO binding/component)
import * as koFirebaseSignIn from '@core/ko_bindings/firebase_sign_in'; koFirebaseSignIn;
import * as koValidationError from '@core/ko_bindings/validation_error'; koValidationError;
import * as koMaterialDropdown from '@core/ko_bindings/material_dropdown'; koMaterialDropdown;
import * as koMaterialSelect from '@core/ko_bindings/material_select'; koMaterialSelect;
import * as koDatePicker from '@core/ko_bindings/date_picker'; koDatePicker;
import * as koValidation from 'knockout.validation'; koValidation;
import * as geoPtValidation from '@core/ko_bindings/geo_pt_validation'; geoPtValidation;
import * as koFormattedDate from '@core/ko_bindings/formatted_date'; koFormattedDate;
import * as koFormattedDateTime from '@core/ko_bindings/formatted_datetime'; koFormattedDateTime;
import * as koTimeAgo from '@core/ko_bindings/time_ago'; koTimeAgo;
import * as koFormattedBoolean from '@core/ko_bindings/formatted_boolean'; koFormattedBoolean;
import * as koFormattedNumber from '@core/ko_bindings/formatted_number'; koFormattedNumber;
import * as koI18nText from '@core/ko_bindings/i18n_text'; koI18nText;
import * as i18nTextInput from '@core/components/i18n_text_input'; i18nTextInput;
import * as basicWidgets from '@core/components/basic_widgets'; basicWidgets;
import * as formSelectSearch from '@core/components/form_select_search'; formSelectSearch;
import * as formDateTimeInput from '@core/components/form_date_time_input'; formDateTimeInput;
import * as formNestedEntities from '@core/components/form_nested_entities'; formNestedEntities;
import * as listLoader from '@core/components/list_loader'; listLoader;
import * as listHeader from '@core/components/list_header'; listHeader;
import * as listFilters from '@core/components/list_filters'; listFilters;
import * as productFiles from './components/product_files'; productFiles;
import * as map from '@core/components/map'; map;
import * as koMapSelect from '@core/ko_bindings/map_select'; koMapSelect;
import * as koMapLocation from '@core/ko_bindings/map_location'; koMapLocation;
import * as koMapSearch from '@core/ko_bindings/map_search'; koMapSearch;
import * as importEntities from './components/import_entities'; importEntities;
import * as koOnClickOutside from '@core/ko_bindings/on_click_outside'; koOnClickOutside;
import * as koTextAreaAutoResize from '@core/ko_bindings/text_area_auto_resize'; koTextAreaAutoResize;
import * as koFormattedMonth from '@core/ko_bindings/formatted_month'; koFormattedMonth;
import * as koValidationNumber from '@core/ko_bindings/validation_number'; koValidationNumber;
import * as productChecklist from './components/product_checklist'; productChecklist;
import * as businessLineComments from './components/business_line_comments'; businessLineComments;
import * as businessLineEditChecklist from './components/business_line_edit_checklist'; businessLineEditChecklist;
import * as dashboardMap from './ko_bindings/dashboard_map'; dashboardMap;
import * as dashboardGroupsChart from './ko_bindings/dashboard_groups_chart'; dashboardGroupsChart;
import * as barChart from './ko_bindings/bar_chart'; barChart;

import { setupRoutes } from './routes';
import { setupApp } from '@core/app';
import { getMenu } from 'menu';
import { fetchNotifications, markNotificationsRead } from 'api/notifications';
import { initSession } from 'app_session';
import { setupErrorLogging } from 'error_logging';

ko.validation.init({
    insertMessages: false
});

i18n.default.setupKnockoutValidationLocalization();

setupRoutes();
setupApp(getMenu, { fetchNotifications, onOpenNotifications: markNotificationsRead });
setupErrorLogging();

window.addEventListener('DOMContentLoaded', () => {
    initSession();
});

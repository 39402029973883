import * as ko from 'knockout';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';

ko.bindingHandlers['firebase-sign-in'] = {
    init: (element: Element, valueAccessor: () => any) => {
        let uiConfig: firebaseui.auth.Config = {
            callbacks: {
                signInSuccessWithAuthResult: () => false,
            },
            signInFlow: 'popup',
            credentialHelper: firebaseui.auth.CredentialHelper.NONE,
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    scopes: ['profile', 'email'],
                    customParameters: {
                        prompt: 'select_account'
                    }
                },
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
        };

        let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start(element, uiConfig);
    }
};

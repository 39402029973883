import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { FactorDefData, factorDefsApi, projectCategoriesApi, ProjectCategoryData } from '../api/simple_api';
import { findById } from '@core/utils';
import { FACTOR_TYPE_OPTIONS } from '../models/factor_def';
import i18n from '@core/i18n';

let template = require('../../templates/factor_defs.html').default;

class FactorDefsScreen implements ListLoaderDelegate<FactorDefData> {
    private categoryFilters = ko.observableArray<ProjectCategoryData>();

    newFilters = [
        { list: projectCategoriesApi.list.bind(projectCategoriesApi), entities: this.categoryFilters, title: i18n.t('Category')() }
    ];

    fetch(params: ListRequestParams) {
        return factorDefsApi.list({ category_ids: this.categoryFilters().map(cat => cat.id), ...params });
    }

    instantiate(data: FactorDefData) {
        return data;
    }

    getEditUrl(entity: FactorDefData): string {
        return '/factors/' + entity.id + '/';
    }

    remove(id: string) {
        return factorDefsApi.remove(id);
    }

    canRemove(data: FactorDefData) {
        return true;
    }

    getTypeName(data: FactorDefData) {
        return findById(FACTOR_TYPE_OPTIONS, data.type).title;
    }
}

export let factorDefs = { name: 'factor-defs', viewModel: FactorDefsScreen, template: template };

ko.components.register(factorDefs.name, factorDefs);

import * as ko from 'knockout';
import { Deferred } from '@core/utils/deferred';
import { businessLinesApi } from 'api/simple_api';

const template = require('../../templates/components/dashboard_contact.html').default;

class DashboardContact {
    private innovationId: string;
    private result: Deferred<{}>;

    email = ko.observable('').extend({ required: true, serverError: true });
    message = ko.observable('').extend({ required: true });
    sending = ko.observable(false);
    sent = ko.observable(false);

    private errorGroup = ko.validation.group([this.email, this.message]);

    constructor(params: { id: string, result: Deferred<{}> }) {
        this.innovationId = params.id;
        this.result = params.result;
    }

    async send() {
        if (this.errorGroup().length > 0) {
            this.errorGroup.showAllMessages();
            return;
        }

        this.sending(true);
        try {
            const res = await businessLinesApi.contact(this.innovationId, { email: this.email(), message: this.message() });
            if (res.isValid) {
                this.sent(true);
            } else {
                if (res.errors['email']) {
                    this.email.serverError(res.errors['email'].join(', '));
                }
            }
        } finally {
            this.sending(false);
        }
    }

    cancel = () => {
        this.result.resolve(null);
    }
}

export let dashboardContact = { name: 'dashboard-contact', viewModel: DashboardContact, template: template };

ko.components.register(dashboardContact.name, dashboardContact);

import * as ko from 'knockout';
import { BusinessLineComment, BusinessLine } from '../models/business_line';
import { MaybeKO, asObservable } from '@core/utils/ko_utils';

const template = require('../../templates/components/business_line_comments.html').default;

export class BusinessLineCommentsModel {
    constructor(public entity: KnockoutObservable<BusinessLine>, private phase: number, private step: string) {
        this.add();
    }

    private comments = ko.pureComputed(() => {
        let comments = this.entity().comments().filter(comment => comment.phase == this.phase && comment.step === this.step);
        comments.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

        return comments;
    });

    readOnlyComments = ko.pureComputed(() => {
        let comments = this.comments();
        return comments.slice(0, comments.length - 1);
    });

    lastComment = ko.pureComputed(() => {
        let comments = this.comments();
        return comments[comments.length - 1];
    });

    removeComment = (comment: BusinessLineComment) => {
        this.entity().comments.remove(comment);
    };

    add = () => {
        let entity = this.entity();
        entity.comments.push(new BusinessLineComment(entity.user, entity.owner, entity.editor, this.phase, this.step));
    };
}

export class BusinessLineComments {
    model: MaybeKO<BusinessLineCommentsModel>;

    constructor(params: { model: MaybeKO<BusinessLineCommentsModel> }) {
        this.model = asObservable(params.model);
    }
}

export let businessLineComments = { name: 'business-line-comments', viewModel: BusinessLineComments, template: template };

ko.components.register(businessLineComments.name, businessLineComments);

import * as ko from 'knockout';

import { Chart } from 'chart.js';
import { MaybeKO } from '@core/utils/ko_utils';
import { DashboardGroupsChartData } from 'api/simple_api';
import { categoryColors, ColorAssignment } from './dashboard_map';

const phaseColors = new ColorAssignment();

function setup(element: Element, data: DashboardGroupsChartData) {
    let prevChart = (element as any).__prevChart as Chart;
    if (prevChart) {
        prevChart.destroy();
    }

    if (!data) {
        return;
    }

    let totals: number[] = [];
    for (let group of data.groups) {
        group.counts.forEach((count, i) => {
            totals[i] = count + (totals[i] ?? 0);
        });
    }
    let max = 0;
    for (let c of totals) {
        max = Math.max(c, max);
    }

    (element as any).__prevChart = new Chart(element as HTMLCanvasElement, {
        type: 'bar',
        data: {
            labels: data.quarters,
            datasets: data.groups.map(group => ({
                label: group.name,
                backgroundColor: (data.use_category_colors ? categoryColors : phaseColors).getColor(group.id),
                data: group.counts
            }))
        },
        options: {
            layout: { padding: 0 },
            maintainAspectRatio: false,
            scales: { 
                yAxes: [{ stacked: true, ticks: { min: 0, max: Math.ceil(max * 1.1), stepSize: 1 } }],
                xAxes: [{ stacked: true }]
            }
        }
    });
}

ko.bindingHandlers['dashboardGroupsChart'] = {
    update: (element: Element, valueAccessor: () => MaybeKO<DashboardGroupsChartData>) => {
        setup(element, ko.unwrap(valueAccessor()));
    }
};

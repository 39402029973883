import * as ko from 'knockout';

import { BaseLoadingScreen } from './base_loading_screen';
import { ValidationResult, ListRequestParams } from '../api/request';
import { Deferred } from '../utils/deferred';
import { SimpleApi } from '../api/simple_api';

export class BaseForm<T extends { id: string | null }> extends BaseLoadingScreen {
    saving = ko.observable(false);
    private result: Deferred<T> | undefined;

    constructor(params: { result?: Deferred<T> }) {
        super();
        this.result = params.result;
    }

    close() {
        if (this.result) {
            this.result.reject();
        } else {
            history.back();
        }
    }

    cancel = () => {
        this.close();
    }

    focusFirst(containerElement: Node) {
        setTimeout(() => {
            if (containerElement.parentElement) {
                $(containerElement.parentElement).find('input:nth(0)').focus();
            }
        }, 0);
    }

    validateLocal(entity: {} | ko.Observable<{}>): boolean {
        let entityValue = ko.unwrap(entity);

        this.clearModelServerErrors(entityValue);

        return this.validate(ko.validation.group(entity, { deep: true }));
    }

    validate(validation: KnockoutValidationErrors): boolean {
        if (validation().length > 0) {
            validation.showAllMessages();
            return false;
        }

        this.saving(true);

        return true;
    }

    executeSaveRequest<T>(request: Promise<T>): Promise<T> {
        request.catch(() => {
            this.saving(false);
        });

        return request;
    }

    onRemoteValidation<TR>(resultData: T, entity: any, validation: ValidationResult<TR>, onClose?: (validation: ValidationResult<TR>) => void) {
        this.saving(false);

        if (validation.isValid) {
            if (onClose) {
                onClose(validation);
            } else if (this.result) {
                resultData.id = validation.entityId;
                this.result.resolve(resultData);
            } else {
                this.close();
            }
        } else {
            this.applyModelServerErrors(entity, validation.errors);
        }
    }

    clearModelServerErrors(entity: {}) {
        if (entity) {
            for (let field in entity) {
                let obsv = <ko.Observable<{}>>((<any>entity)[field]);
                if (ko.isObservable(obsv) && obsv.serverError) {
                    obsv.serverError(null);
                }
            }
        }
    }

    applyModelServerErrors(entity: any, errors: { [field: string]: string[] }) {
        for (let field in errors) {
            let entityField = this.snakeCaseToCamelCase(field);
            if (ko.isObservable(entity[entityField]) && entity[entityField].serverError) {
                this.setServerError(entity[entityField], errors[field]);
            }
        }
    }

    private snakeCaseToCamelCase(value: string): string {
        let newParts = [];
        for (let part of value.split('_')) {
            if (newParts.length > 0 && part.length > 0) {
                part = part.charAt(0).toUpperCase() + part.slice(1);
            }
            newParts.push(part);
        }

        return newParts.join('');
    }

    setServerError(obsv: ko.Observable<{}>, errors: string[]) {
        if (obsv.serverError) {
            obsv.serverError(errors.join('. '));
        }
    }

    saveEntity<TR>(api: SimpleApi<T, ListRequestParams, {}, TR>, entity: ko.Observable<{ toData(): T }>, globalError: ko.Observable<string>, onDone?: (validation: ValidationResult<TR>) => void, onInvalid?: () => void) {
        globalError('');

        if (this.validateLocal(entity)) {
            let data = entity().toData();
            this.executeSaveRequest(api.save(data)).then((validation) => {
                this.onRemoteValidation(data, entity(), validation, onDone);

                if (!validation.isValid) {
                    let globalErrors =  (validation.errors['__all__'] || [] as string[]).concat(validation.errors['non_field_errors'] || []);
                    if (globalErrors.length > 0) {
                        globalError(globalErrors.join('. '));
                    }
                    onDone?.(validation);
               }
            });
        } else {
            onInvalid?.();
        }
    }
}

import * as ko from 'knockout';
import { Deferred } from '@core/utils/deferred';
import { businessLinesApi, usersApi, UserData, BusinessLineData, BusinessLineListData } from 'api/simple_api';
import { makeChecklist, ChecklistRow, ChecklistUserType, CHECKLIST_SUPERVISOR_USER_TYPE, CHECKLIST_PM_USER_TYPE, checklistToData } from 'models/business_line_checklist';
import i18n from '@core/i18n';
import { userRole } from 'app_session';

const template = require('../../templates/components/business_line_edit_checklist.html').default;

const phases = [
    i18n.t('Definition')(),
    i18n.t('Designing')(),
    i18n.t('Development')(),
    i18n.t('Pre-commercial')(),
    i18n.t('Commercial')(),
];

class BusinessLineEditChecklist {
    checklist = ko.observable<ChecklistRow[]>();
    phase = ko.observable('');
    loading = ko.observable(true);
    saving = ko.observable(false);

    private result: Deferred<BusinessLineListData>;
    private userData: UserData;
    private data: BusinessLineData;

    constructor(params: { id: string, result: Deferred<BusinessLineListData> }) {
        this.result = params.result;

        let userPromise = usersApi.me();
        let dataPromise = businessLinesApi.retrieve(params.id);
        Promise.all([userPromise, dataPromise]).then(([userData, data]) => {
            this.userData = userData;
            this.data = data;

            let userType = userData.id === data.owner?.id ? CHECKLIST_PM_USER_TYPE : CHECKLIST_SUPERVISOR_USER_TYPE;
            let checklist = makeChecklist(data.checklist);
            let phaseIdx = 0;
            for (let item of checklist) {
                if (!item.every(check => check.states.every(state => state.userType !== userType || state.value() === 'approved'))) {
                    this.checklist(item);
                    break;
                }
                phaseIdx++;
            }
            if (!this.checklist()) {
                phaseIdx = 0;
                this.checklist(checklist[0]);
            }
            this.phase(phases[phaseIdx] + ' - ' + i18n.t('Progression checklist')());
            this.loading(false);
        });
    }

    canEdit = (userType: ChecklistUserType) => {
        let isSupervisor = userRole() === 'admin' || this.userData.id === this.data.supervisor?.id;
        let isOwner = isSupervisor || this.userData.id === this.data.owner?.id;

        return (userType !== CHECKLIST_SUPERVISOR_USER_TYPE && isOwner) || isSupervisor;
    };

    save = async () => {
        this.saving(true);
        try {
            let data = checklistToData([this.checklist()]);
            let slugs = this.checklist().map(item => item.slug);
            await businessLinesApi.updateChecklist(this.data.id, data, slugs);
            this.result.resolve(await businessLinesApi.listItem(this.data.id));
        } finally {
            this.saving(false);
        }
    };

    cancel = () => {
        this.result.reject();
    };
}

export let businessLineEditChecklist = { name: 'business-line-edit-checklist', viewModel: BusinessLineEditChecklist, template: template };

ko.components.register(businessLineEditChecklist.name, businessLineEditChecklist);

import * as ko from 'knockout';

import { RegionData } from '../api/simple_api';

export class Region {
    private id: string | null = null;

    name = ko.observable('').extend({ required: true });

    constructor(data: RegionData) {
        if (data) {
            this.id = data.id;
            this.name(data.name);
        }
    }

    toData(): RegionData {
        return {
            id: this.id,
            name: this.name()
        };
    }
}

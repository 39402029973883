import * as ko from 'knockout';
import { Point } from '@core/ko_bindings/map_location';
import { PartnerData, SiteData } from '../api/simple_api';
import { getPartnerSearch } from './helpers/search_configs';
import { asI18nText, I18nText } from '@core/i18n_text';
import { canEditSites } from 'permissions';

export class Site {
    id: string | null = null;
    nameJson = ko.observable<I18nText>(asI18nText('')).extend({ i18nTextRequired: true, serverError: true });
    locationLatLng = ko.observable<Point | null>(null);
    partners = ko.observableArray<PartnerData>();

    partnerSearch = getPartnerSearch(this.partners);

    canEdit = true;

    constructor(data?: SiteData) {
        if (data) {
            this.canEdit = data.can_edit && canEditSites();

            this.id = data.id;
            this.nameJson(data.name_json);
            this.partners(data.partners);
            if (data.location_lat && data.location_lon) {
                this.locationLatLng({ lat: data.location_lat, lng: data.location_lon });
            }
        }
    }

    toData(): SiteData {
        return {
            id: this.id,
            name_json: this.nameJson(),
            partners: this.partners(),
            location_lat: this.locationLatLng()?.lat ?? null,
            location_lon: this.locationLatLng()?.lng ?? null
        };
    }
}

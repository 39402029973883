import page from 'page';

import { app } from '@core/app';
import { requireAuthorized, LoginParams } from '@core/screens/login';
import { users } from './screens/users';
import { userEdit } from './screens/user_edit';
import { businessLines } from './screens/business_lines';
import { businessLineEdit } from './screens/business_line_edit';
import { projectCategories } from './screens/project_categories';
import { projectCategoryEdit } from './screens/project_category_edit';
import { factorDefs } from './screens/factor_defs';
import { factorDefEdit } from './screens/factor_def_edit';
import { crops } from './screens/crops';
import { partners } from './screens/partners';
import { partnerEdit } from './screens/partner_edit';
import { suggestedBusinessLines } from './screens/suggested_business_lines';
import { suggestedBusinessLineEdit } from './screens/suggested_business_line_edit';
import { setupBaseRoutes, setupCreateUpdateRoutes, getFiltersParams } from '@core/utils/routes';
import { notFound } from '@core/screens/not_found';
import { regionEdit } from 'screens/region_edit';
import { regions } from 'screens/regions';
import { tags } from 'screens/tags';
import { tagEdit } from 'screens/tag_edit';
import { businessLinesByRole } from 'screens/business_lines_by_role';
import { dashboardDetails } from 'screens/dashboard_details';
import { session } from '@core/session';
import { dashboard } from 'screens/dashboard';
import { dashoardSettingsEdit } from 'screens/dashboard_settings_edit';
import { userTags } from 'screens/user_tags';
import { userTagEdit } from 'screens/user_tag_edit';
import { sites } from 'screens/sites';
import { siteEdit } from 'screens/site_edit';
import { documentation } from 'screens/documentation';
import { documentationEdit } from 'screens/documentation_edit';

const loginParams: LoginParams = { motto: '' };

function appRequiredAuthorized(ctx: PageJS.Context, next: () => any) {
    requireAuthorized(loginParams, ctx, next);
}

export function setupRoutes() {
    setupBaseRoutes();

    page('/', appRequiredAuthorized, () => {
        page.redirect(defaultPage());
    });

    page('/business_line/promote/:promoteId/', appRequiredAuthorized, (ctx: PageJS.Context) => {
        app.currentPage({ name: businessLineEdit.name, params: { id: null, promoteId: ctx.params.promoteId } });
    });

    page('/innovations/:id/:phase/:step/', appRequiredAuthorized, (ctx: PageJS.Context) => {
        app.currentPage({ name: businessLineEdit.name, params: { id: ctx.params.id, phase: ctx.params.phase, step: ctx.params.step, include: 'all' } });
    });

    setupCreateUpdateRoutes(appRequiredAuthorized, [
        { baseName: 'users', listComponent: users, editComponent: userEdit },
        { baseName: 'innovations', listComponent: businessLines, editComponent: businessLineEdit, params: { include: 'all' } },
        { baseName: 'pm_innovations', listComponent: businessLinesByRole, editComponent: businessLineEdit, params: { include: 'project_manager' } },
        { baseName: 'supervisor_innovations', listComponent: businessLinesByRole, editComponent: businessLineEdit, params: { include: 'supervisor' } },
        { baseName: 'suggested_innovations', listComponent: suggestedBusinessLines, editComponent: suggestedBusinessLineEdit },
        { baseName: 'project_categories', listComponent: projectCategories, editComponent: projectCategoryEdit },
        { baseName: 'factors', listComponent: factorDefs, editComponent: factorDefEdit },
        { baseName: 'crops', listComponent: crops },
        { baseName: 'partners', listComponent: partners, editComponent: partnerEdit },
        { baseName: 'regions', listComponent: regions, editComponent: regionEdit },
        { baseName: 'tags', listComponent: tags, editComponent: tagEdit },
        { baseName: 'user_tags', listComponent: userTags, editComponent: userTagEdit },
        { baseName: 'sites', listComponent: sites, editComponent: siteEdit },
        { baseName: 'documentation', listComponent: documentation, editComponent: documentationEdit }
    ]);

    page('/dashboard_settings/', appRequiredAuthorized, () => {
        app.currentPage({ name: dashoardSettingsEdit.name });
    });

    // public endpoints temporarily disabled until the dashboard is complete

    // public endpoint
    page('/dashboard/', appRequiredAuthorized, (ctx: PageJS.Context) => {
        app.showNavigationBar(session.authorized());
        app.currentPage({ name: dashboard.name, params: getFiltersParams(ctx) });
    });

    // public endpoint
    page('/dashboard_details/', appRequiredAuthorized, (ctx: PageJS.Context) => {
        app.showNavigationBar(session.authorized());
        app.currentPage({ name: dashboardDetails.name, params: getFiltersParams(ctx) });
    });

    page('*', () => {
        app.currentPage({ name: notFound.name });
    });
}

function defaultPage() {
    return '/dashboard/';
}

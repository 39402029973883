import { pad } from '../utils/strings';

import * as ko from 'knockout';
import { unwrap } from '../utils/ko_utils';

interface IdData {
    id?: string | ko.Observable<string>;
}

export function inflateModelListFrom<T extends { id: () => string }>(ids: string[], available: T[]) {
    if (!ids || !available) {
        return [];
    }

    return ids.map((id) => {
        return available.filter((data) => data.id() == id)[0];
    }).filter((data) => !!data);
}

export function inflateSingleFrom<T extends IdData>(id: string, available: T[]) {
    if (!id || !available) {
        return null;
    }

    return available.filter((data) => unwrap(data.id) == id)[0];
}

export function inflateSingleModelFrom<T extends { id: () => string }>(id: string, available: T[]) {
    if (!id || !available) {
        return null;
    }

    return available.filter((data) => data.id() == id)[0];
}

export function deflateSingle(entity: IdData) {
    return entity ? unwrap(entity.id) : undefined;
}

const ISO_DATE_REGEXP = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;

export function parseDate(dateString: string | null) {
    if (!dateString || typeof dateString !== 'string') {
        return null;
    }

    let match = dateString.match(ISO_DATE_REGEXP);
    if (!match) {
        return null;
    }

    return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
}

const ISO_DATE_TIME_REGEXP =  /^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)(\.(\d\d\d))?Z$/;

export function parseDateTime(dateString: string) {
    if (!dateString) {
        return null;
    }

    let match = dateString.match(ISO_DATE_TIME_REGEXP);
    if (!match) {
        throw new Error('Can\'t parse datetime: ' + dateString);
    }

    return new Date(Date.UTC(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10),
        match[8] === undefined ? 0 : parseInt(match[8], 10)
    ));
}

export function serializeDate(date: Date | null) {
    if (!date) {
        return null;
    }

    return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
}

export function serializeDateTime(date: Date) {
    if (!date) {
        return null;
    }

    return date.toISOString();
}

export function serializeNumber(value: string | number): number | null {
    if (typeof value === 'string' && !(<string>value).trim()) {
        return null;
    }

    return <number>value;
}

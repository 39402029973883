import * as ko from "knockout";

import { DocumentationData, documentationApi } from "../api/simple_api";
import { Deferred } from "@core/utils/deferred";
import { createWithComponent } from "@core/utils/ko_utils";
import { BaseEditScreen } from "@core/screens/base_edit";
import { Documentation } from "models/documentation";

let template = require("../../templates/documentation_edit.html").default;

class DocumentationEditScreen extends BaseEditScreen<
  DocumentationData,
  Documentation
> {
  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<DocumentationData>;
    },
    componentInfo: ko.components.ComponentInfo
  ) {
    super({ api: documentationApi, focus: true }, params, componentInfo);
  }

  instantiate(data: DocumentationData) {
    return new Documentation(data);
  }
}

export let documentationEdit = {
  name: "documentation-edit",
  viewModel: createWithComponent(DocumentationEditScreen),
  template: template,
};

ko.components.register(documentationEdit.name, documentationEdit);

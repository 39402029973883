import { NotificationData } from '@core/api/base_notifications';
import { request } from '@core/api/request';

export function fetchNotifications(): Promise<NotificationData> {
    return request('GET', '/api/notifications/');
}

export function markNotificationsRead() {
    request('POST', '/api/notifications/mark_notifications_read/');
}

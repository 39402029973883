import * as ko from 'knockout';

import { CountryData, OrganizationTypeData, PartnerData, UserData } from '../api/simple_api';
import { getCountrySearch, getOrgTypeSearch, getUserSearch } from './helpers/search_configs';

export class Partner {
    private id: string | null = null;

    name = ko.observable('').extend({ required: true });
    country = ko.observable<CountryData>(null).extend({
        required: true
    });
    contact = ko.observable('').extend({
        serverError: true
    });
    address = ko.observable('').extend({
        serverError: true
    });
    phone = ko.observable('').extend({
        serverError: true
    });
    email = ko.observable('').extend({
        serverError: true
    });
    website = ko.observable('').extend({
        serverError: true
    });
    organizationType = ko.observable<OrganizationTypeData>(null).extend({
        required: true
    });
    responsible = ko.observable<UserData>(null);

    countrySearch = getCountrySearch(this.country);
    responsibleSearch = getUserSearch(this.responsible);
    organizationTypeSearch = getOrgTypeSearch(this.organizationType);

    constructor(data: PartnerData) {
        if (data) {
            this.id = data.id;
            this.name(data.name);
            this.country(data.country);
            this.contact(data.contact);
            this.address(data.address);
            this.phone(data.phone);
            this.email(data.email);
            this.website(data.website);
            this.organizationType(data.organization_type);
            this.responsible(data.responsible);
        }
    }

    toData(): PartnerData {
        return {
            id: this.id,
            name: this.name(),
            country: this.country(),
            contact: this.contact(),
            address: this.address(),
            phone: this.phone(),
            email: this.email(),
            website: this.website(),
            organization_type: this.organizationType(),
            responsible: this.responsible()
        };
    }
}

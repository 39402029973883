import * as ko from 'knockout';
import page from 'page';

import { session } from '../session';
import { app } from '../app';

let template = require('../../templates/login.html').default;

export interface LoginParams {
    motto: string;
}

class LoginScreen {
    loading = ko.observable<boolean>(false);
    loggedIn = session.loggedIn;
    authorized = session.authorized;
    userEmail = ko.observable('');

    motto: string;

    constructor(params: LoginParams) {
        this.motto = params.motto;
        session.onLoggedIn = this.onLoggedIn;
        this.userEmail(session.getEmail() ?? '');
    }

    private onLoggedIn = async (state: 'loading' | 'loaded', authorized: boolean) => {
        this.userEmail(session.getEmail() ?? '');

        if (state === 'loading') {
            this.loading(true);
        } else {
            this.loading(false);

            if (authorized) {
                page();
            }
        }
    };

    dispose() {
        session.onLoggedIn = null;
    }
}

export let login = { name: 'login', viewModel: LoginScreen, template: template };

ko.components.register(login.name, login);

export function requireAuthorized(params: LoginParams, ctx: PageJS.Context, next: () => any) {
    if (session.authorized()) {
        next();
    } else {
        app.currentPage({ name: login.name, params });
    }
}

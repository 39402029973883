import * as ko from 'knockout';

ko.bindingHandlers['onClickOutside'] = {
    init: (element: Element, valueAccessor: () => any) => {
        let listener = (event: Event) => {
            if (!event.target) {
                return;
            }

            let $target = $(event.target);
            if ($target.closest(element).length == 0) {
                valueAccessor()();
            }
        };

        // setTimeout to avoid triggering the event in case this element
        // is created during a click event handler
        setTimeout(() => {
            document.addEventListener('click', listener);
        }, 0);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            document.removeEventListener('click', listener);
        });
    }
};

import { userRole } from 'app_session';
import { NavGroup } from '@core/components/basic_widgets';
import i18n from '@core/i18n';
import { canEditFactorDefs, canEditRegions, canEditPartners, canEditTags } from './permissions';

export function getMenu() {
    let groups: NavGroup[] = [];

    groups.push(new NavGroup('', [{ title: i18n.t('Dashboard')(), icon: 'dashboard', href: '/dashboard/' }]));
    if (userRole() === 'admin') {
        groups.push(new NavGroup('', [{ title: i18n.t('Users')(), icon: 'person', href: '/users/' }]));
        groups.push(new NavGroup('', [{ title: i18n.t('User Tags')(), icon: 'label', href: '/user_tags/' }]));
    }
    groups.push(new NavGroup(i18n.t('Innovations')(), [
        { title: i18n.t('Innovations')(), icon: 'business', href: '/innovations/' },
        { title: i18n.t('Project manager innovations')(), icon: 'business', href: '/pm_innovations/' },
        { title: i18n.t('Supervisor innovations')(), icon: 'business', href: '/supervisor_innovations/' }
    ]));
    if (userRole() === 'admin' || userRole() === 'user') {
        groups.push(new NavGroup('', [{ title: i18n.t('Scorecard')(), icon: 'public', href: '/suggested_innovations/' }]));
    }

    if (canEditPartners()) {
        groups.push(new NavGroup('', [{ title: i18n.t('Partners')(), icon: 'people', href: '/partners/' }]));
    }
    groups.push(new NavGroup('', [{ title: i18n.t('Crops')(), icon: 'local_florist', href: '/crops/' }]));
    if (userRole() === 'admin') {
        groups.push(new NavGroup('', [{ title: i18n.t('Categories')(), icon: 'category', href: '/project_categories/' }]));
    }
    if (canEditTags()) {
        groups.push(new NavGroup('', [{ title: i18n.t('Tags')(), icon: 'label', href: '/tags/' }]));
    }
    if (canEditRegions()) {
        groups.push(new NavGroup('', [{ title: i18n.t('Regions')(), icon: 'terrain', href: '/regions/' }]));
    }

    groups.push(new NavGroup('', [{ title: i18n.t('Sites')(), icon: 'location_on', href: '/sites/' }]));

    if (canEditFactorDefs()) {
        groups.push(new NavGroup('', [{ title: i18n.t('Factors library')(), icon: 'library_books', href: '/factors/' }]));
    }

    if (userRole() === 'admin') {
        groups.push(new NavGroup('', [{ title: i18n.t('Dashboard settings')(), icon: 'settings_applications', href: '/dashboard_settings/' }]));
    }

    groups.push(new NavGroup('', [{ title: i18n.t('Documentation')(), icon: 'menu_book', href: '/documentation/' }]));


    groups.push(new NavGroup('', [{ title: i18n.t('Logout')(), icon: 'exit_to_app', href: '/logout/' }]));

    return groups;
}

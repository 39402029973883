import * as ko from 'knockout';

import { RegionData, regionsApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { Region } from '../models/region';

let template = require('../../templates/region_edit.html').default;

class RegionEditScreen extends BaseEditScreen<RegionData, Region> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<RegionData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: regionsApi, focus: true }, params, componentInfo);
    }

    instantiate(data: RegionData) {
        return new Region(data);
    }
}

export let regionEdit = { name: 'region-edit', viewModel: createWithComponent(RegionEditScreen), template: template };

ko.components.register(regionEdit.name, regionEdit);

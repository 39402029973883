import * as ko from 'knockout';

const greyPin = require('../../images/grey_pin.png').default;

export interface Point {
    lat: number;
    lng: number;
}

declare const google: any;

let map: any;
let markers: any[] = [];

function resetMarkers(position: Point, others: Point[]) {
    for (let marker of markers) {
        marker.setMap(null);
    }
    markers = [];
    for (let point of others) {
        markers.push(new google.maps.Marker({
            position: point,
            map,
            icon: greyPin
        }));
    }
    if (position) {
        markers.push(new google.maps.Marker({ position, map }));
    }
}

function fitAll(position: Point, others: Point[]) {
    if (!position && others.length === 0) {
        return;
    }

    let bounds = new google.maps.LatLngBounds();
    if (position) {
        bounds.extend(position);
    }
    for (let point of others) {
        bounds.extend(point);
    }
    map.fitBounds(bounds);
}

interface Params {
    enable: boolean;
    obs: ko.Observable<Point>;
    others: Point[];
}

ko.bindingHandlers['mapLocation'] = {
    init: (element: Element, valueAccessor: () => Params) => {
        let params = valueAccessor();

        const mapElem = document.getElementById('map');
        if (!mapElem) {
            return;
        }

        ko.cleanNode(mapElem);
        element.appendChild(mapElem);

        if (!map) {
            map = new google.maps.Map(mapElem, {
                mapTypeId: 'hybrid',
                tilt: 0, // a 45 deg tilt shows polylines in the wrong place
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });
        }

        for (let control of map.controls) {
            if (control) {
                control.clear();
            }
        }

        let location = ko.unwrap(params.obs);
        if (location || params.others.length > 0) {
            map.setZoom(15);
        } else {
            map.setCenter({ lat: 47.535, lng: 7.578 });
            map.setZoom(8);
        }

        let listener = map.addListener('click', function (event: any) {
            if (params.enable) {
                params.obs({ lat: event.latLng.lat(), lng: event.latLng.lng() });
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            google.maps.event.removeListener(listener);
            document.getElementById('map-container')?.appendChild(mapElem);
        });
    },

    update: (element: Element, valueAccessor: () => Params) => {
        let params = valueAccessor();
        let location = ko.unwrap(params.obs);
        resetMarkers(location, params.others);
        fitAll(location, params.others);
    }
};

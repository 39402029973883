import * as ko from 'knockout';

export const showNeedPageReload = ko.observable(false);

export function requestWithStatus<ResultType>(method: string, path: string, authToken?: string, params?: {}): Promise<{ status: number, result: ResultType }> {
    return new Promise((resolve, reject) => {
        let req = makeRequest(method, path, authToken);

        req.onload = () => {
            checkVersion(req);

            let result = <ResultType>{};
            if (req.status == 200 || req.status == 201 || req.status == 204 || req.status == 400 || req.status == 403) {
                try {
                    result = JSON.parse(req.responseText);
                } catch (e) {
                    result = <ResultType>{};
                }
            }

            resolve({ status: req.status, result: result });
        };
        req.onerror = () => {
            reject();
        };
        if (params) {
            req.send(JSON.stringify(params));
        } else {
            req.send();
        }
    });
}

export function requestRawWithStatus(method: string, path: string, authToken: string, params?: {}): Promise<{ status: number, data: Blob }> {
    return new Promise((resolve, reject) => {
        let req = makeRequest(method, path, authToken);
        req.responseType = 'blob';

        req.onload = () => {
            checkVersion(req);
            resolve({ status: req.status, data: req.response });
        };
        req.onerror = () => {
            reject();
        };
        if (params) {
            req.send(JSON.stringify(params));
        } else {
            req.send();
        }
    });
}

function makeRequest(method: string, path: string, authToken: string | undefined) {
    let req = new XMLHttpRequest();

    // avoid ajax caching in IE
    if (path.indexOf('?') === -1) {
        path = path + '?';
    }
    path = path + '&cache=' + (new Date().getTime()) + '&version=' + VERSION;

    req.open(method, path);
    req.setRequestHeader('Content-Type', 'application/json');
    req.setRequestHeader('Accept', 'application/json');
    if (authToken) {
        req.setRequestHeader('Authorization', authToken);
    }
    let userLanguage = localStorage.getItem('userLanguage');
    if (userLanguage !== null) {
        req.setRequestHeader('X-User-Language', userLanguage);
    }

    return req;
}

function checkVersion(req: XMLHttpRequest) {
    let serverVersion = req.getResponseHeader('X-Version');
    showNeedPageReload(serverVersion && VERSION !== serverVersion);
}

import * as ko from 'knockout';
import page from 'page';

import { FormsStackController } from './components/forms_stack';
import { session } from './session';
import { NavGroup, NotificationsDelegate } from './components/basic_widgets';

class AppViewModel {
    currentPage = ko.observable<{ name: string, fullWidth?: boolean, params?: {} } | null>(null);

    authorized = session.authorized;
    showNavigationBar = ko.observable(true);

    formsStackController = new FormsStackController();

    showLoggedOut = ko.observable(false);
    showServerError = ko.observable(false);
    showNetworkError = ko.observable(false);
    showEntityNotFound = ko.observable(false);
    showTourOverlay = ko.observable(false);

    private getMenu: () => NavGroup[] = () => [];
    notificationsDelegate?: NotificationsDelegate;

    initMenu(getMenu: () => NavGroup[], notificationsDelegate?: NotificationsDelegate) {
        this.getMenu = getMenu;
        this.notificationsDelegate = notificationsDelegate;
    }

    confirmLoggedOut = () => {
        session.logout().then(() => {
            this.showLoggedOut(false);
            location.href = location.href; // reload the page to login again
        });
    };

    closeServerError = () => {
        this.showServerError(false);
    };

    closeNetworkError = () => {
        this.showNetworkError(false);
    }

    closeEntityNotFound = () => {
        this.showEntityNotFound(false);
        page.redirect('/');
    }

    menu = ko.pureComputed(() => this.getMenu());
}

export let app = new AppViewModel();

export function setupApp(getMenu: () => NavGroup[], notificationsDelegate?: NotificationsDelegate) {
    app.initMenu(getMenu, notificationsDelegate);
    ko.applyBindings(app, document.body);
}

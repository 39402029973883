import * as ko from 'knockout';
import { DashboardSettingsData } from '../api/simple_api';

export class DashboardSettings {
    titleLine1 = ko.observable('').extend({ required: true });
    titleLine2 = ko.observable('').extend({ required: true });
    subtitle = ko.observable('').extend({ required: true });
    description = ko.observable('').extend({ required: true });

    constructor(data?: DashboardSettingsData) {
        if (data) {
            this.titleLine1(data.title_line_1);
            this.titleLine2(data.title_line_2);
            this.subtitle(data.subtitle);
            this.description(data.description);
        }
    }

    toData(): DashboardSettingsData {
        return {
            title_line_1: this.titleLine1(),
            title_line_2: this.titleLine2(),
            subtitle: this.subtitle(),
            description: this.description()
        };
    }
}
